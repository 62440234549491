import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Rating from '@material-ui/lab/Rating';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

import HomeWorkIcon from '@material-ui/icons/HomeWork';
import IconText from "./IconText"
//  {<IconText text={"Company"} icon={<HomeWorkIcon fontSize="small" />}/>}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionBox: {
        marginLeft: "-13px",
        marginRight: "-13px",
        paddingBottom: "1px"
    },
    accordionBoxLast: {
        marginLeft: "-13px",
        marginRight: "-13px",

    },


    accordion: {
        marginLeft: "-13px!important",
        marginRight: "-13px!important",
        paddingBottom: "1px",
        borderTop: "1px solid #EEEEEE"

    },
    activeFilters: {
        minHeight: "20px",
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: theme.typography.pxToRem(14),
    }

}));



export default function FilterCompany(props) {
    const { accordionClassName, showInDialog, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    var accordionClass = classes.accordion
    if (accordionClassName) {
        accordionClass = accordionClassName
    }


    var selectedCompanySize = useSelector(state => state.selectedCompanySize)

    var selectedCompanySizeTextValues = useSelector(state => state.values.selectedCompanySizeTextValues)


    const handleChange = (event, newValue) => {
        dispatch(({ type: "SET_SELECTED_COMPANY_SIZE", selectedValue: newValue }))
    };

    var toggleButtons = []
    for (const [key, value] of Object.entries(selectedCompanySizeTextValues)) {
        toggleButtons.push(
            <ToggleButton key={"company_size_"+value} value={key}  aria-label="quilt" style={{ minWidth: "184px" }}>
            <span style={{ textTransform: "none", color: "black" }}>{value}</span>
        </ToggleButton>

        )
    }

    return (
        <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >

                <Typography className={classes.heading}> {<IconText text={"Company"} icon={<HomeWorkIcon fontSize="small" />}/>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", width: "100%" }}>

                    <ToggleButtonGroup orientation="vertical" value={selectedCompanySize} exclusive onChange={handleChange}>
                        {toggleButtons}
                       
                    </ToggleButtonGroup>



                </div>

            </AccordionDetails>
        </Accordion>


    );
}


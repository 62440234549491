import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Rating from '@material-ui/lab/Rating';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

import IconText from "./IconText"
//  {<IconText text={"Date"} icon={<EventIcon fontSize="small" />}/>}

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import EventIcon from '@material-ui/icons/Event';
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionBox: {
        marginLeft: "-13px",
        marginRight: "-13px",
        paddingBottom: "1px"
    },
    accordionBoxLast: {
        marginLeft: "-13px",
        marginRight: "-13px",

    },


    accordion: {
        marginLeft: "-13px!important",
        marginRight: "-13px!important",
        paddingBottom: "1px",
        borderTop: "1px solid #EEEEEE"

    },
    activeFilters: {
        minHeight: "20px",
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: theme.typography.pxToRem(14),
    }

}));



export default function FilterDate(props) {
    const { accordionClassName, showInDialog, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    var accordionClass = classes.accordion
    if (accordionClassName) {
        accordionClass = accordionClassName
    }


    var selectedDate = useSelector(state => state.selectedDate)
    var selectedDateValue = useSelector(state => state.selectedDateValue)

    var selectedDateValues = useSelector(state => state.values.selectedDateTextValues)

    const handleChange = (event, newValue) => {
        dispatch(({ type: "SET_SELECTED_DATE", selectedValue: newValue }))
    };


    function handleDateChange(date) {

        dispatch(({ type: "SET_SELECTED_DATE_VALUE", selectedValue: date }))
    }


    var customRangeItem = null
    if (selectedDate === "7") {
        customRangeItem = (
            <div style={{ width: "184px" }}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Since"
                        format="dd.MM.yyyy"
                        value={selectedDateValue}
                        onClick={
                            e => {
                                if (selectedDate == "7") {
                                    console.log("STOP PROPAGATION: " + selectedDate)
                                    e.stopPropagation()
                                }
                                else {
                                    console.log("DO NOT STOP PROPAGATION: " + selectedDate)
                                }
                            }}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}



                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }


    var toggleButtons = []
    for (const [key, value] of Object.entries(selectedDateValues)) {
        toggleButtons.push(
            <ToggleButton   key={"dates_"+value[0]} value={key} aria-label="list" style={{ minWidth: "184px" }}>
                <span style={{ color: "black", marginLeft: "10px", textTransform: "none" }}>{value[0]}</span>

            </ToggleButton>
        )
    }

    return (
        <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >

                <Typography className={classes.heading}>{<IconText text={"Date"} icon={<EventIcon fontSize="small" />}/>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", width: "100%" }}>

                    <div>
                    <ToggleButtonGroup orientation="vertical" value={selectedDate} exclusive onChange={handleChange}>

                        {toggleButtons}

                    </ToggleButtonGroup>
                    
                    {customRangeItem}
                    </div>
             

                </div>
            
               

            </AccordionDetails>
        </Accordion>


    );
}


import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import ItemList from "../components/ItemList";
import ItemSearchField from "../components/ItemSearchField";
import ItemFilter from "../components/ItemFilter";
import ItemSort from "../components/ItemSort";

import { NavLink, withRouter } from "react-router-dom";


import {fetchData} from "../data/LoadItems";

import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';






function JobSearch(props) {
    const { history,...other } = props;
    const dispatch = useDispatch();


    useLayoutEffect(() => {
        document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    }, [])


    let user              = useSelector(state => state.user)
  


    const openLoginPage = () =>{
        dispatch({ type: "SET_LOGIN_REGISTER_TAB", value:0 })
        window.scroll(0, 0);
        history.push("/account");
    }

    const openRegisterPage = () =>{
        dispatch({ type: "SET_LOGIN_REGISTER_TAB", value:1 })
        window.scroll(0, 0);
        history.push("/account");
    }

    let savedItems = null
    let logginOrRegister = null

    if(user){
        savedItems =
        <> 
        <ItemSort mode={"saved"}/>
        <ItemList mode={"saved"}/>
        </>
    }
    else{
        logginOrRegister = <div style={{marginTop:"50px",width:"100%",height:"100%",textAlign: "center",
            verticalAlign: "middle",
            lineHeight: "30px"
        }}>
            <br />
 
            <span style={{fontSize: "20px",lineHeight: "20px",color:"#70757a"}}>{"Sign in to save jobs that interest you"}</span>
            <br />
            <br />
            <br />
            <Button variant="contained" color="primary" onClick={()=>{openLoginPage()}}> Sign In</Button>
            <br />
            <br />
 


            <Button  color="primary" onClick={()=>{openRegisterPage()}}> Create Account</Button>


        </div>


        
        
        
    
       
    }


    return (
        <div class="inner-container">
     

            {savedItems}
            {logginOrRegister}
            
            <div style={{ marginBottom: "110px", marginTop: "0px" }} />


        </div>
    )
}




export default withRouter(JobSearch);
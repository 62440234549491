import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'



import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ColoredProgress from "./ColoredProgress";

import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';



import NothingFound from "./NothingFound";

import Item from "./Item"; 

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100% - 90px)",
    overflowY: "auto",
    backgroundColor: "#f7f7f7",
  },





}));


var rootElement = null
var scrollStateTimeout = null
var checkScrollState = false


function startCheckScrollState(dispatch){
  checkScrollState = true
  window.onscroll = function(ev) {
    if(checkScrollState){
      if(!rootElement){
          rootElement = document.getElementById("root")
      }

      if ((window.innerHeight + window.scrollY) >= rootElement.offsetHeight-window.innerHeight*0.2-10) {
        if(!scrollStateTimeout){
      
          dispatch(({ type: "SHOW_MORE_JOBS"}))
          scrollStateTimeout = setTimeout(()=>{
            clearTimeout(scrollStateTimeout)
            scrollStateTimeout = null
          },100)
        }
      }
    }
};
}

function stopCheckScrollState(dispatch){
  window.onscroll = ()=>{}
  checkScrollState = false
};



export default function JobList(props) {
  const { mode, ...other } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [scrollableDiv] = React.useState(newId('scrollableDiv'))


  let jobList         =useSelector(state => state.jobList);
  let savedJobs         =useSelector(state => state.savedJobs);
 // let savedJobsLength         =useSelector(state => state.savedJobs.length);
  let visibleJobsNum  =useSelector(state => state.visibleJobsNum);
  let loading         =useSelector(state => state.loading);
  let searchValueText = useSelector(state => state.searchValueText);

  useLayoutEffect(() => {
    startCheckScrollState(dispatch)
    return () => {
       stopCheckScrollState(dispatch)
    };

  }, [])



  var itemList = []

  if(mode=="saved"){
    itemList = savedJobs
  }
  else{
    itemList = jobList
  }



  var listItems = []

  var num = itemList.length


  if (num > visibleJobsNum) {
    num = visibleJobsNum
  }


  const createJobItem = (job) => {
    //Element.scrollIntoView()
    return <Item key={"jobitem_"+job.slug}  job={job} showRating={true} showCompany={true} />
  }



  for (var i = 0; i < num; i++) {
    var job = itemList[i]
    if(job && job.slug){
      listItems.push(createJobItem(job))
    }
  }
  let loadingItem = null
  if(loading){
    loadingItem = <ColoredProgress/>
  }


  var noResultsItem = null
  if(mode!="saved"){
    if(listItems.length==0 && !loading){
      noResultsItem = <NothingFound text={searchValueText}/>
    }
  }






  return (

  

      <div >
        <div  style={{"height":"3px","marginLeft":"13px","marginRight":"13px","marginTop": "-1px","marginBottom":"-7px","overflow":"hidden","borderBottomLeftRadius":"5px","borderBottomRightRadius":"5px"}} >
        {loadingItem}
        </div>
        <List>
          
          {listItems}
        </List>
        {noResultsItem}
        <div style={{"height":"50px"}} >

        </div>
        
      </div>

    

  );
}

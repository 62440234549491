import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import ValidatedTextField from './components/ValidatedTextField';
import LoginRegisterError from "./components/LoginRegisterError";


function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }



var styles = function styles(theme) {
  return {
    root: {},
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    field: {
      marginTop: theme.spacing.unit
    },
    actions: {
      marginTop: theme.spacing.unit * 2
    }
  };
};

var LocalUserLogin = function (_Component) {
  _inherits(LocalUserLogin, _Component);

  function LocalUserLogin(props) {
    _classCallCheck(this, LocalUserLogin);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.disableSubmit = function () {
      _this.setState({ canSubmit: false });
    };

    _this.enableSubmit = function () {
      _this.setState({ canSubmit: true });
    };

    _this.submit = function (model) {
      if (_this.props.onLogin) {
        _this.props.onLogin(model);
      }
    };

    _this.state = {
      canSubmit: false
    };
    return _this;
  }

  LocalUserLogin.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        loginFailed = _props.loginFailed;
    var canSubmit = this.state.canSubmit;

    return React.createElement(
      'div',
      { className: classes.root },
      React.createElement(
        Formsy,
        { className: classes.form,
          onValid: this.enableSubmit, onInvalid: this.disableSubmit,
          onValidSubmit: this.submit },
        React.createElement(ValidatedTextField, {
          name: 'username',
          autoComplete: 'username',
          validations: 'minLength:3',
          validationErrors: {
            minLength: "Too short"
          },
          required: true,
          className: classes.field,
          label: _props.t.EMail,
        }),
        React.createElement(ValidatedTextField, {
          type: 'password',
          name: 'password',
          autoComplete: 'current-password',
          validations: 'minLength:2',
          validationErrors: {
            minLength: "Too short"
          },
          required: true,
          className: classes.field,
          label: _props.t.Password


        }),
        loginFailed && React.createElement(LoginRegisterError, { message: loginFailed }),
        React.createElement(
          'div',
          { className: classes.actions },
          React.createElement(
            Button,
            { 
              style:{ textTransform: "none" },
              type: 'submit',
              fullWidth: true,
              variant: 'contained', color: 'primary',
              disabled: !canSubmit },

              _props.t.LogIn
    
            
          )
        )
      )
    );
  };

  return LocalUserLogin;
}(Component);

LocalUserLogin.propTypes = process.env.NODE_ENV !== "production" ? {
  onLogin: PropTypes.func,
  loginFailed: PropTypes.string
} : {};


export default withStyles(styles)(LocalUserLogin);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";


import BackToTop from "react-back-to-top-button";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';



import { getTabIndex } from "../utils/Tabs";



const NavigationItem = (props) => {
    const { history,location,...other } = props;
    const dispatch = useDispatch();
  
    let sessionStartTime = useSelector(state => state.sessionStartTime)
    

    const selectTabIndexFromPath = (pathname) =>{
 
        var newTabIndex = getTabIndex(pathname)

        dispatch(({ type: "SET_TAB_INDEX", tabIndex: newTabIndex }))
    }


    const loadPageContent = (pathname,pathArray, hideLoading, locationState,initialize) => {

        var jobID = null
        var companyID = null

        if (pathArray.length >= 2) {
            var pathArray2 = ""
            var pathArray3 = ""
            if (pathArray.length >= 3) {
                pathArray2 = pathArray[2]
            }
            if (pathArray.length >= 4) {
                pathArray3 = pathArray[3]
            }
            
            var itemType = pathArray[1].toLowerCase()
            switch (itemType) {
                case "job":
              
                    jobID = pathArray2
                    break;

                case "company":
                    companyID = pathArray2
                    break;
                case "companies":
                    
                    break;
                case "postjob":
                   
                    break;
                case "account":
 
                    break;
            }
        }
     
        selectTabIndexFromPath(pathname)
        
        if(locationState!="NODATARELOAD_"+sessionStartTime){
            dispatch(({ type: "FETCH_DATA",path:pathname, initialize: initialize, jobID: jobID, companyID: companyID, hideLoading: hideLoading }))
        }
       


    }

    useLayoutEffect(() => {
        var pathArray = location.pathname.split('/');
        loadPageContent(location.pathname,pathArray,false,location.state,true)

        dispatch({ type: "HISTORY", action:"PUSH", location:location.pathname })

        history.listen((newLocation, action) => {

            var pathArray = newLocation.pathname.split('/');
            if(action!="POP"){
                loadPageContent(newLocation.pathname,pathArray,newLocation.pathname!="/",newLocation.state,false)
            }
            else{
                selectTabIndexFromPath(newLocation.pathname)
            }
            

            dispatch({ type: "HISTORY", action:action, location:newLocation.pathname,pathArray:pathArray })
            console.log(action, newLocation.pathname, location.state)
            console.log(newLocation)
        });
    }, [])








    

    return (
        
            <BackToTop

                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >

                <Fab component="div" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>

            </BackToTop>



    )
}




export default withRouter(NavigationItem);
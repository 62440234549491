import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'cordova_script';
import { createStore } from 'redux'

import { Provider } from 'react-redux'
import jobStore from './store/store'
const store = createStore(jobStore)

document.addEventListener('deviceready', () => {

    ReactDOM.render(
        <Provider store={store}>
        <div>
            <App cordovaWork={true} />
        </div></Provider>
        ,
        
        document.getElementById('root')
    );
}, false);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


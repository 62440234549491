






let sizeState = {
    width: document.documentElement.clientWidth,
    height:document.documentElement.clientHeight,

    widthValues:{filterLeft:{size:1380,value:0},sortBar:{size:438,value:0},mobile:{size:610,value:0},appBar:{size:730,value:0},appBarMini:{size:350,value:0}, logoMini:{size:395,value:0}},

    heightValues:{}
}



const updateWindowSize = (dispatch) =>{
    var changed = false
    for (const [key, sizeValue] of Object.entries(sizeState.widthValues)) {
        var size = sizeValue.size
        var value = sizeValue.value
        var v = -1
        if(size <= sizeState.width){
            v = 1
        }
        if(v!=value){
 
            sizeState.widthValues[key].value = v
            changed = true
        }
    }
    for (const [key, sizeValue] of Object.entries(sizeState.heightValues)) {
        var size = sizeValue.size
        var value = sizeValue.value
        var v = -1
        if(size <= sizeState.width){
            v = 1
        }
        if(v!=value){
 
            sizeState.heightValues[key].value = v
            changed = true
        }
    }
    
    if(changed){
        dispatch({type:"RESIZE",widthValues:sizeState.widthValues,heightValues:sizeState.heightValues})
    }
}


const changeWindowSize = (dispatch) => ()=>{
    // Get width and height of the window excluding scrollbars
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    
    sizeState.width = width
    sizeState.height = height

    updateWindowSize(dispatch)
    
   // alert(width+", "+height)

}



const startResizeService = (dispatch) => {
    updateWindowSize(dispatch)
    window.addEventListener("resize", changeWindowSize(dispatch));

}



export { startResizeService, sizeState }

import React, { useEffect, useLayoutEffect } from 'react';


import SearchIcon from '@material-ui/icons/Search';
import BuildIcon from '@material-ui/icons/Build';

const NothingFound = (props) => {
    const { text } = props;

    if(text){
        return (
            <div style={{marginTop:"50px",width:"100%",height:"100%",textAlign: "center",
            verticalAlign: "middle",
            lineHeight: "30px" }}>
              
              <div style={{display:"inline-block",verticalAlign: "middle",marginLeft:"-20px",marginRight:"2px"}}><SearchIcon  fontSize="small" style={{paddingTop: "3px"}} /></div> <div  style={{display:"inline-block"}}>{"We couldn't find anything for "  }</div> <b>{text}</b>
              <br/>
              <span>{"Try different or less specific keywords."}</span>
              
              </div>
        )
    }
    else{
        return <div style={{marginTop:"50px",width:"100%",height:"100%",textAlign: "center",
        verticalAlign: "middle",
        lineHeight: "30px" }}>
          
          <div style={{display:"inline-block",verticalAlign: "middle",marginLeft:"-20px",marginRight:"2px"}}><BuildIcon  fontSize="small" style={{paddingTop: "3px"}} /></div> <div  style={{display:"inline-block"}}>{"Our website is undergoing some maintenance."  }</div>
          <br/>
          <span>{"We'll be back soon."}</span>
          
          </div>
    }
   
}







export default NothingFound;
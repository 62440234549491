


import React, { useEffect, useLayoutEffect } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '@material-ui/core/styles';



const ColoredProgress = (props) => {

    const { classes } = props;

    return (

        <LinearProgress {...props} classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} />

    )
}

const styles = props => ({
    colorPrimary: {
        //backgroundColor: '#FFE5CE',
    },
    barColorPrimary: {
       // backgroundColor: '#F8B575',
    }
});

export default withStyles(styles)(ColoredProgress);






import {fetchData} from "./LoadItems";


import {setGetInfoWebsites} from "../utils/Utils";


// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});



let dispatchFunc = null
let setDispatch = (dispatch) =>{
    dispatchFunc = dispatch
}


let dispatch = (action) => {
    setTimeout(()=>{
        if(dispatchFunc){
            dispatchFunc(action)
        }
        else{
            dispatch(action)
        }
    },1)
}



const updateStateData = (state,newState, action,updateData) => {
 

    if(updateData.reload){
        if(newState.jobFilterDialogOpen){
            newState.itemsNeedReload = true
            newState.loading = false
        }
        else{
            fetchData(dispatch,state,newState, action)
        }
    }


    switch (action.type) {
     
        case "NEW_DATA":
            
            break;

    }


    return newState
}

export  {updateStateData,setDispatch }
import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';


import {useSelector,  useDispatch } from 'react-redux'




import Slider from '@material-ui/core/Slider';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';


import EuroIcon from '@material-ui/icons/Euro';
import IconText from "./IconText"
//  {<IconText text={"Salary"} icon={<EuroIcon fontSize="small" />}/>}

function valuetext(value) {
  return `${value}°C`;
}

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  root: {

    marginTop: "-10px",
    marginLeft: "4px",
    width: "172px",
  }
  ,
  accordionBox: {
    marginLeft: "-13px",
    marginRight: "-13px",
    paddingBottom: "1px"
  },
  accordionBoxLast: {
    marginLeft: "-13px",
    marginRight: "-13px",

  },


  accordion: {
    marginLeft: "-13px!important",
    marginRight: "-13px!important",
    paddingBottom: "1px",
    borderTop: "1px solid #EEEEEE"

  },
  activeFilters: {
    minHeight: "20px",
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: theme.typography.pxToRem(14),
  }

}));


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      isNumericString
      suffix=" €"
    />
  );
}


let fetchSalaryJobsDelayTimeout = null


export default function FilterSalary(props) {
  const { accordionClassName, ...other } = props;
  const classes = useStyles();

  var accordionClass = classes.accordion
  if (accordionClassName) {
    accordionClass = accordionClassName
  }

  const dispatch = useDispatch();

  var value = useSelector(state => state.selectedSalaryValues)
  var salaryValues = useSelector(state => state.values.salaryValues)
  var changeSelectedSalaryValueText = useSelector(state => state.changeSelectedSalaryValueText)

  const [fromValue, setFromValue] = React.useState(value[0]);
  const [toValue, setToValue] = React.useState(value[1]);

  var selectedSalaryRange = useSelector(state => state.selectedSalaryRange)



  const handleSalaryRangeChange = (newSalaryRangeValue)=>(event) => {
    var newSalaryRange = newSalaryRangeValue
    if(event.target.checked){
        dispatch(({ type: "SET_SELECTED_SALARY_RANGE", selectedValue: newSalaryRange }))
    }
};

  var perPeriodItems = []

  perPeriodItems.push(
    <FormControlLabel
      key={"perYear"}
      control={
        <Checkbox
          size={"small"}
          checked={selectedSalaryRange=="year"}
          onChange={handleSalaryRangeChange("year")}
          name={"per year"}
          color={"primary"}
        />
      }
      label={"per year"}
    />)

  perPeriodItems.push(
  
    <FormControlLabel
    key={"perMonth"}
      control={
        <Checkbox
          size={"small"}
          checked={selectedSalaryRange=="month"}
          onChange={handleSalaryRangeChange("month")}
          name={"per month"}
          color={"primary"}
        />
      }
      label={"per month"}
    />)



  if(changeSelectedSalaryValueText){
    if(fromValue!= value[0]){
      setFromValue(value[0])
    }
    if(toValue!= value[1]){
      setToValue(value[1])
    }
  }

  const handleChange = (event, newValue,doNotChangeTextValues) => {
    if(!doNotChangeTextValues){
      setFromValue(newValue[0]);
      setToValue(newValue[1]);
    }
    if(fetchSalaryJobsDelayTimeout){
      clearTimeout(fetchSalaryJobsDelayTimeout)
    }
    fetchSalaryJobsDelayTimeout= setTimeout(()=>{
      fetchSalaryJobsDelayTimeout = null
      dispatch(({ type: "FETCH_DATA"}))
    },1000)

    dispatch(({ type: "SET_SELECTED_SALARY_VALUES", selectedValues: newValue }))
  };



  const handleFromValueChange = (event) => {
    var newToValue   = toValue
    var newFromValue = event.target.value

    setFromValue(newFromValue);

    if(newFromValue == ""){
      newFromValue = salaryValues[0]
    }

    handleChange(event,[newFromValue,newToValue],true)
  };


  const handleToValueChange = (event) => {
    var newToValue = event.target.value
    console.log("handleToValueChange")
    console.log(newToValue)


    var newFromValue = fromValue


    setToValue(newToValue);


    if(newToValue == ""){
      newToValue = salaryValues[1]
    }

    handleChange(event,[newFromValue,newToValue],true)
  };

  var fromValueText = fromValue
  var fromValueFormat = {}
  if(fromValueText == salaryValues[0] || fromValueText == ""){
    fromValueText  = ""
    fromValueFormat = {endAdornment: <InputAdornment position="end">0 €</InputAdornment>, inputComponent: NumberFormatCustom }
  }
  else{
    fromValueFormat = { inputComponent: NumberFormatCustom }
  }

  var toValueText = toValue
  var toValueFormat = {}
  if(toValueText == salaryValues[1] || toValueText == ""){
    toValueText  = ""
    toValueFormat = {endAdornment: <InputAdornment position="end">∞</InputAdornment>, inputComponent: NumberFormatCustom }
  }
  else{
    toValueFormat = { inputComponent: NumberFormatCustom }
  }





  return (
    <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >

        <Typography className={classes.heading}>{<IconText text={"Salary"} icon={<EuroIcon fontSize="small" />}/>}  </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div style={{ marginLeft: "0px", marginRight: "15px" }}>
            <Slider

              value={value}
              onChange={handleChange}

              aria-labelledby="range-slider"

              min={salaryValues[0]}

              max={salaryValues[1]}

              onChange={handleChange}
              style={{ marginBottom: "5px", marginLeft: "10px", marginRight: "10px" }}


            />
          </div>
          <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", width: "100%" }}>

            <div style={{ marginLeft: "0px", marginRight: "0px" }}>
              <form noValidate autoComplete="off" style={{ width: "100%" }} >

                <TextField InputProps={fromValueFormat} size="small" id="minwage" label="From" onChange={handleFromValueChange}  value={fromValueText} variant="outlined" style={{ fontSize: "small", margin: "5px", marginBottom: "10px", width: "172px" }} />
                <TextField InputProps={toValueFormat} size="small" id="maxwage" label="To" onChange={handleToValueChange} value={toValueText} variant="outlined" style={{ fontSize: "small", margin: "5px", marginBottom: "10px", width: "172px" }} />

              </form>

              <FormGroup row>
                <div style={{ marginLeft: "4px" }}>
                  {perPeriodItems}
                </div>


              </FormGroup>

              </div>

            
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>

  );
}


import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'



import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


import FilterWebsites from "./FilterWebsites";
import FilterSalary from "./FilterSalary";
import FilterRegions from "./FilterRegions";
import FilterLanguages from "./FilterLanguages";
import FilterRating from "./FilterRating";
import FilterApplication from "./FilterApplication";

import FilterDate from "./FilterDate";
import FilterCategory from "./FilterCategory";
import FilterCompany from "./FilterCompany";
import ActiveFilters from "./ActiveFilters";





const useStyles = makeStyles(theme => ({
  root: {

    position: "absolute",
    top: "143",//"103px",

    width: "200px",
    left: "calc(50% - 680px)",

    paddingBottom: "0px!important",



    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
  }
  ,
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  accordionBox: {
    marginLeft: "-13px",
    marginRight: "-13px",
    paddingBottom: "1px"
  },
  accordionBoxLast: {
    marginLeft: "-13px",
    marginRight: "-13px",

  },

  accordionDialog: {
    paddingBottom: "1px",
    borderTop: "1px solid #5f5f5"

  },

  accordion: {
    marginLeft: "-13px!important",
    marginRight: "-13px!important",
    paddingBottom: "1px",
    borderTop: "1px solid #EEEEEE"

  },
  activeFilters: {
    minHeight: "20px",
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: theme.typography.pxToRem(14),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function FilterDialog(props) {
  const { onClose, open, ...other } = props;
  const classes = useStyles();

  let sortBarValue              = useSelector(state => state.widthValues.sortBar?state.widthValues.sortBar.value:0)

  React.useEffect(() => {

  }, []);

  const handleEntering = () => {
  
  };


  const handleOk = () => {
    onClose();
  };

 

  return (
    <Dialog
      TransitionComponent={Transition}
      disableBackdropClick
      fullScreen = {sortBarValue!=1}
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      onClose={handleOk}
      open={open}
      scroll={"body"}
      {...other}
      fullWidth={true}
    
    >
      {/*<DialogTitle id="scroll-dialog-title">Filters</DialogTitle>*/}
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleOk}>
          <CloseIcon />
        </IconButton>
      <DialogContent >
        <div style={{marginTop:"15px"}}>
        <FilterContent showActiveFilters={true} showInDialog={true} />
        </div>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function FilterContent(props) {
  const { showActiveFilters,showInDialog, ...other } = props;
  const classes = useStyles();
  const dispatch = useDispatch();


  var accordionClass = classes.accordion
  if(showInDialog){

    accordionClass = classes.accordionDialog
  }

  

  return (<>

    <ActiveFilters showInDialog={showInDialog}/>

    <FilterRegions accordionClassName={accordionClass} showInDialog={showInDialog}/>
    <FilterLanguages accordionClassName={accordionClass} showInDialog={showInDialog}/>

    <FilterCategory accordionClassName={accordionClass} />

    <FilterDate accordionClassName={accordionClass} />

    <FilterRating accordionClassName={accordionClass} />

    <FilterSalary accordionClassName={accordionClass} />

    <FilterCompany accordionClassName={accordionClass} />

    {/*<FilterApplication accordionClassName={accordionClass} />

  <FilterWebsites accordionClassName={accordionClass}/>*/}
    </>
  )
}


export default function JobFilter(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let filterLeftValue              = useSelector(state => state.widthValues.filterLeft?state.widthValues.filterLeft.value:0)

  let jobFilterDialogOpen              = useSelector(state => state.jobFilterDialogOpen)

  var findItem =null



  const handleClickOpen = () => {
    dispatch(({ type: "SET_FILTER_DIALOG_OPEN", value: true }))
   
  };

  const handleClose = (newValue) => {
    dispatch(({ type: "SET_FILTER_DIALOG_OPEN", value: false }))

  };

  if(filterLeftValue==1){

    if(jobFilterDialogOpen){
      dispatch(({ type: "SET_FILTER_DIALOG_OPEN", value: false }))
    }


  findItem =
    <Paper className={classes.root} elevation={1} style={{ overflow: "hidden", padding: "10px",marginBottom:"10px"}}>


      <FilterContent showActiveFilters={true} showInDialog={false} />




    </Paper>
  }
  else{
    findItem =
    <Paper style={{float: "left",marginTop: "0px",marginRight: "0px",marginLeft: "12px", height:"48px",verticalAlign:"center"}}>
        <Button style={{textTransform:"none",color:"black",float: "left",height:"48px",marginTop: "0px",marginRight: "0px",marginLeft: "0px"}}  color="primary" onClick={handleClickOpen}>
        Filters
      </Button>
      <FilterDialog
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={jobFilterDialogOpen}
          onClose={handleClose}
    
        />
     
      </Paper>
      
        
  }


  return (

    <>
    
      {findItem}
  

    </>

  );
}


import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import LanguageIcon from '@material-ui/icons/Language';

import IconText from "./IconText"
//  {<IconText text={"Websites"} icon={<LanguageIcon fontSize="small" />}/>}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({
   
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionBox: {
        marginLeft: "-13px",
        marginRight: "-13px",
        paddingBottom: "1px"
    },
    accordionBoxLast: {
        marginLeft: "-13px",
        marginRight: "-13px",

    },


    accordion: {
        marginLeft: "-13px!important",
        marginRight: "-13px!important",
        paddingBottom: "1px",
        borderTop: "1px solid #EEEEEE"

    },
    activeFilters: {
        minHeight: "20px",
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: theme.typography.pxToRem(14),
    }

}));



export default function FilterWebsites(props) {
    const {accordionClassName, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();


    var websites = useSelector(state => state.websites)
    var websitesNum = useSelector(state => state.websites.length)

    var websitesItems = []

    const handleWebsiteChange = (event) => {
        dispatch(({ type: "SET_WEBSITE_CHECKED", website: event.target.name, checked: event.target.checked }))
    };


    const handleSelectAllWebsitesChange = (event) => {
        dispatch(({ type: "SET_ALL_WEBSITES_CHECKED", checked: event.target.checked }))
    };

    var allSelectedState = true
    var noneSelectedState = true

    websites.forEach((website) => {
        if (website.jobSource) {
            var color = "primary"
            var disabled = false
            var formControlLabelStyle = {minWidth:"100px"}
            if (website.title === "Rejoco") {
                disabled = true
                formControlLabelStyle = {minWidth:"100px"/*,marginTop:"10px"*/ }
            }
            else {
                if (!website.checked) {
                    allSelectedState = false
                }
                else {
                    noneSelectedState = false
                }


            }
            websitesItems.push(
                <FormControlLabel style={formControlLabelStyle}    key = {website.id+"_checkbox"}
                    control={
                        <Checkbox
                         
                            size = {"small"}
                            checked={website.checked}
                            onChange={handleWebsiteChange}
                            name={website.title}
                            color={color}
                            disabled={disabled}
                        />
                    }
                    label={website.title}
                />
            )
           

        }
    })

    var allChecked = !noneSelectedState
    var allIndeterminate = !noneSelectedState && !allSelectedState
    websitesItems.unshift(<div class="breakFlex"    key = {"break_0"}></div>)
    websitesItems.unshift(
        <FormControlLabel style={{/*marginBottom:"5px"*/ }}    key = {"all_checkbox"}
            control={
                <Checkbox 
                    key = {"allChecked"}
                    size = {"small"}
                    checked={allChecked}
                    indeterminate={allIndeterminate}
                    onChange={handleSelectAllWebsitesChange}
                    name={"Select All"}
                    color={"primary"}
                />
            }
            label={"All Websites"}
        />)

    var accordionClass = classes.accordion
    if (accordionClassName) {
        accordionClass = accordionClassName
    }

    return (
        <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >

                <Typography className={classes.heading}> {<IconText text={"Websites"} icon={<LanguageIcon fontSize="small" />}/>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>

                    <FormGroup row>
                        <div style={{marginLeft:"4px"}}>
                        {websitesItems}
                        </div>
                        

                    </FormGroup>


                </Typography>
            </AccordionDetails>
        </Accordion>


    );
}


{/* <div className={classes.accordionBox}> 




<FormControlLabel
                  control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" />}
                  label="Secondary"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Primary"
                />
                <FormControlLabel control={<Checkbox name="checkedC" />} label="Uncontrolled" />
                <FormControlLabel disabled control={<Checkbox name="checkedD" />} label="Disabled" />
                <FormControlLabel disabled control={<Checkbox checked name="checkedE" />} label="Disabled" />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedF}
                      onChange={handleChange}
                      name="checkedF"
                      indeterminate
                    />
                  }
                  label="Indeterminate"
                />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedG} onChange={handleChange} name="checkedG" />}
                  label="Custom color"
                />
                <FormControlLabel
                  control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />}
                  label="Custom icon"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="checkedI"
                    />
                  }
                  label="Custom size"
                />

*/}
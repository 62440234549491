import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'
import ItemFilter from "../components/ItemFilter";


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';
import { getJobPicture, getJobPortalName, getCompanyValues } from "../utils/Utils";
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import Rating from '@material-ui/lab/Rating';

import { NavLink, withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {


    },



}));


var defaultRating = 4


function Item(props) {
    const { company, page, history, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    let loading = useSelector(state => state.loading);
    let websites = useSelector(state => state.websites);


    const [companyValues, setCompanyValues] = React.useState(getCompanyValues(websites, company));

    const itemRef = React.useRef();
    const detailsRef = React.useRef();

    var openURL = companyValues.url


    if (typeof company.hideDetailsFast === 'undefined' || company.hideDetailsFast == null) {
        company.hideDetailsFast = true
    }

    if (typeof company.rating === 'undefined' || company.rating == null) {
        company.rating = defaultRating
    }






    var jobID = company.slug
    if (!jobID) {
        jobID = company._id
    }

    var openDetailsURL = "/company/" + jobID





    function isTopInViewport(element, offset) {
        if (!offset) {
            offset = 0
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= offset
        );
    }

    function isBottomInViewport(element, height) {
        if (!height) {
            height = 0
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top + height <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }



    const handleWebsiteClick = () => {


        if (page) {
            window.open(openURL, "_blank")
        }

    }

    const handleCompanyDetailsClick = () => {


        if (page) {
            window.open(openURL, "_blank")
        }

    }

    const handleSaveClick = () => {
        dispatch({ type: "TOOGLE_ITEM_SAVE", company: company })
    }


    const handleItemClick = () => {
        if (!page) {

            var scrollIntoViewOpen = !company.open

            var hideDetailsFast = false



            var itemHeight = null

            var collapsedItemHeight = 126

            if (company.itemHeight) {
                collapsedItemHeight = company.itemHeight
            }

            if (!scrollIntoViewOpen) {
                if (itemRef.current) {
                    if (!isTopInViewport(itemRef.current, -collapsedItemHeight)) {

                        if (itemRef.current) {
                            //itemRef.current.scrollIntoView({ block: "start", behavior: "auto" });
                            var y = window.scrollY - (itemRef.current.clientHeight - collapsedItemHeight)
                            if (y >= 0) {
                                window.scroll(0, y);
                                hideDetailsFast = true
                            }
                            else {
                                hideDetailsFast = true
                                if (itemRef.current) {
                                    itemRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
                                }
                            }
                        }
                    }
                }
            }
            else {
                itemHeight = 126
                if (itemRef.current) {
                    itemHeight = itemRef.current.offsetHeight
                }
            }

            dispatch(({ type: "TOOGLE_ITEM_OPEN", company: company, hideDetailsFast: hideDetailsFast, itemHeight: itemHeight }))

            if (scrollIntoViewOpen) {
                if (itemRef.current && detailsRef.current) {
                    if (!isBottomInViewport(itemRef.current, detailsRef.current.offsetHeight)) {
                        setTimeout(() => {
                            if (itemRef.current) {
                                itemRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
                            }
                        }, 10)
                    }
                }
            }
        }



    };





    var itemDetails = null
    var expandLessMore = null
    var expandLessMoreSpace = null

    var expandLessMoreBottom = null





    if (!company.open) {
        expandLessMoreSpace = <div style={{ width: "100%", height: "10px" }}></div>
    }

    var descStyle = {}
    if (loading) {
        descStyle = { color: "#dedede" }
    }

    if (!page) {
        expandLessMoreBottom =
            <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "41px", cursor: "pointer" }}>
                <IconButton size="small" style={{ float: "right", "marginTop": "9px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                    <ExpandLess />
                </IconButton>


            </div>
    }
    else {
        expandLessMoreBottom = <div style={{ width: "100%", height: "15px" }}></div>
    }


    var webPageButton = null

    if (openURL) {
        webPageButton = (<div style={{ marginTop: "1px", marginLeft: "20px", marginRight: "20px", marginBottom: "10px" }} >

            <Button style={{ float: "right", textTransform: "none", marginTop: "5px" }} variant="contained" color="primary" onClick={(event) => { handleWebsiteClick(event) }} >{companyValues.domain}</Button>

        </div>)
    }




    itemDetails = <div ref={detailsRef}>
        <div >
            <div style={{ marginTop: "1px", marginLeft: "20px", marginRight: "20px", marginBottom: "10px" }} >

                <div style={{ "clear": "both", height: "6px" }}></div>



            </div>

        </div>


        {webPageButton}


        <div style={{ "clear": "both" }}></div>
        {expandLessMoreBottom}



    </div>





    if (!page) {
        if (!company.open) {
            expandLessMore = (
                <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "31px", cursor: "pointer", "borderTop": "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <IconButton size="small" style={{ float: "right", "marginTop": "1px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                        <ExpandMore />
                    </IconButton>
                </div>)

        }
        else {
            expandLessMore = (
                <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "41px", cursor: "pointer", "borderTop": "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <IconButton size="small" style={{ float: "right", "marginTop": "1px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                        <ExpandLess />
                    </IconButton>

                </div>)
        }
    }


    let precision = 1 

    if (Math.abs(companyValues.rating - Math.round(companyValues.rating)) > 0.25) {
        precision = 0.5
    }


    var ratingItem = null

    if (companyValues.rating > 0) {
        ratingItem = <div title={Math.round(companyValues.rating*10)/10} style={{ marginRight: "10px" }} ><Rating  readOnly name="size-small" size="small" defaultValue={companyValues.rating} precision={precision} /></div> 
    }


    var jobItem = (
        <Paper className="jobitem" elevation={1} ref={itemRef} >
            <div className="jobitemInnerContainer" style={{}} >
                <ListItem div  /* onClick={(event) => { handleCompanyDetailsClick(event); event.preventDefault() }}*/ component="div" >
                    <ListItemIcon>
                        <img width={30} height={30} style={{ objectFit: "contain", width: "30px", height: "30px" }} src={companyValues.logoURL}></img>
                    </ListItemIcon>
                    <ListItemText

                        primary={<div style={{ marginBottom: "-4px" }}> <span title={company.company} style={{ textOverflow: "ellipsis", whiteSpace: "normal", display: "inline-block", "fontSize": "1.2rem", maxWidth: "calc(100% - 30px)", overflow: "hidden" }}>{company.company}</span></div>}

                        secondary={
                            <>
                                {ratingItem}
                                <span style={{ display: "block", "clear": "both", height: "4px" }}></span>
                                {companyValues.region ? <span title={companyValues.region} style={{ display: "block", marginRight: "10px", float: "left", "fontSize": "0.7rem", "color": "rgba(0, 0, 0, 0.40)", textOverflow: "ellipsis", whiteSpace: "normal", maxWidth: "calc(100% - 30px)", overflow: "hidden" }}>{"📍 " + companyValues.region}</span> : null}

                            </>
                        }
                    />


                </ListItem>
                {expandLessMore}

                <Collapse onExited={() => { company.hideDetailsFast = true; dispatch(({ type: "UPDATE_ITEM_LIST" })) }} in={(company.open && !loading) || page} collapsedHeight={0}>
                    {expandLessMoreSpace}
                    {itemDetails}

                </Collapse>



            </div>
        </Paper>
    )


    return jobItem
}


export default withRouter(Item);



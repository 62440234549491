import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'
import ItemFilter from "./ItemFilter";


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";

import { NavLink, withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {


  },





}));




function ItemSearchField(props) {
  const { history, ...other } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const searchValue = useSelector(state => state.companySearchValue); //React.useState('Custom Sort');

  const handleSearchChange = (newSearchValue) => {

    dispatch(({ type: "SET_COMPANY_SEARCH_VALUE", companySearchValue: newSearchValue, startCompanySearch: false }))


  };

  const handleStartSearch = (newSearchValue) => {
    //window.history.replaceState(null, null, "/")
    if (newSearchValue) {
      history.push("/companies?q=" + newSearchValue);
    }
    dispatch(({ type: "SET_COMPANY_SEARCH_VALUE", companySearchValue: newSearchValue, startCompanySearch: true }))
  };

  var findItem =



    function fetchData() {

    }
  let hasMore = true;


  
  let sortBarValue              = useSelector(state => state.widthValues.sortBar?state.widthValues.sortBar.value:0)
  

  var searchBarStyle = { width: "100%" }



  var searchBarPlaceholder = ""
  if(sortBarValue==1){
    searchBarPlaceholder = "Search by Company Name or Description"
  }
  else{
    searchBarPlaceholder = "Search"
  }

  return (

    <div className="companysearch" style={{ position: "relative", marginBottom: "1px", padding: "0px", whiteSpace: "nowrap", height: "50px" }}>

      <SearchBar
        placeholder={searchBarPlaceholder}
        //dataSource={[ "Software","Developer","Engineer"]}
        value={searchValue}
        onChange={(newValue) => handleSearchChange(newValue)}
        onRequestSearch={() => handleStartSearch(searchValue)}
        onCancelSearch={() => handleStartSearch("")}
        cancelOnEscape={true}
        
        style={searchBarStyle}
      />
     

    </div>





  );
}

export default withRouter(ItemSearchField);

/*
   <Paper className="jobsearch" elevation={1} style={{ marginBottom: "1px", padding: "10px" }}>
    </Paper>
    */
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";
import LoginRegister from '../components/react-mui-login-register';
import ColoredProgress from "../components/ColoredProgress";


let tabIndexCallback = ()=>{}


const LoginRegisterPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    let loggingIn              = useSelector(state => state.loggingIn)
    let token              = useSelector(state => state.token)

    let loginRegisterTabValue = useSelector(state => state.loginRegisterTab)
  

    const [loginRegisterTabSaved,setLoginRegisterTabSaved] = React.useState(-1)



    
    if(loginRegisterTabValue!=loginRegisterTabSaved){
       // alert(loginRegisterTabValue+" "+loginRegisterTabSaved)
         setLoginRegisterTabSaved(loginRegisterTabValue)
        setTimeout(()=>{
            tabIndexCallback(loginRegisterTabValue)
        },1)
       
       
    }

    //Coming soon! Everyone who registers to sign up will be added to a waitlist. You do not need to re-register.

    const onTabChange = (value) =>{

        if(typeof value !== 'undefined'){
            dispatch({ type: "SET_LOGIN_REGISTER_TAB", value:value })
            setLoginRegisterTabSaved(value)
          
        }
        
    }


    const handleLogin = content => {
       // alert(`Logging in: '${JSON.stringify(content)}'`);
        dispatch({ type: "LOGIN", login:{"username":content.username} })
    };  
    
    const handleRegister = content => {
       // alert(`Registering: '${JSON.stringify(content)}'`);
        dispatch({ type: "REGISTER", register:{"username":content.username} })
    };

    const handleLoginWithProvider = providerId => {
      //  alert(`Logging in: '${providerId}'`);
    };



    const handleRegisterWithProvider = providerId => {
       // alert(`Registering: '${providerId}'`);
    };

    var loginFailed = null;
    var registerFailed = null;


    if(token=="login_error_password"){
        loginFailed = "The password is invalid or the user does not have a password."
    }
    if(token=="register_error_email_in_use"){
        registerFailed = "The email address is already in use by another account."
    }



    return (
        <div  style={{marginTop:"15px"}}>
            
            <LoginRegister

                t = {{
                    EMail:"Email",
                    Password:"Password",
                    LogIn:"Sign In",
                    EnterEMail:"Email",
                    EnterPassword:"Password",
                    EnterPasswordAgain:"Confirm Password",
                    Register:"Join Waitlist (Public Beta)",

                }}
              

                tabIndex = {loginRegisterTabValue}

             //   header = {<>Login</>}
             //   disableRegister = {true}
                providers = {[]}
                onLogin={handleLogin}
                onRegister={handleRegister}
              //  onLoginWithProvider={this.handleLoginWithProvider}
            
              //  onRegisterWithProvider={this.handleRegisterWithProvider}
                getTabIndexCallback ={(callback)=>{tabIndexCallback = (value)=>{callback(null,value)}}}
                onTabChange={(value)=>{onTabChange(value)  }} //  forgot passwort message beneath on login
                footer={ loggingIn?<ColoredProgress/>:null }
                loginFailed={loginFailed}
                registerFailed={registerFailed}
            />
           

      
        </div>
    )
}




export default LoginRegisterPage;
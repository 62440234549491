import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const AppInfoBox = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();


    const showAbout = ()=>{
        document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
        window.scroll(0, 0);
        history.push("/about");
    }


    
    const openPage = (title,url) => (event) => {
        if (!event.ctrlKey && event.which != 2 && event.which != 3) {

            history.push(url,"NORELOAD");
              

            document.title = title
          
            window.scroll(0, 0);


            event.preventDefault()
        }
    }


   

    return (
     
        <div className="App-Info" >


            <div className="wrapper">

                <div className="container" >

                    <div style={{ float: "left", marginLeft: "10px", marginTop: "3px" }} >

                        <div onClick={() => { showAbout() }} style={{ float: "left", marginRight: "30px", cursor: "pointer" }}>
                            <div style={{ color: "#9C7B5C", float: "left", marginRight: "4px", marginTop: "2px" }}>
                                <HelpOutlineIcon fontSize="small" />

                            </div>
                            <div style={{ color: "#9C7B5C", float: "left" }}>
                                Impressum
                        </div>
                        </div>

                        <div style={{ color: "#9C7B5C", float: "left", marginRight: "20px", cursor: "pointer" }} onClick={openPage("Rejoco - Disclamer", "/disclamer")}>
                            {"Datenschutzerklärung"}
                        </div>
                        {/*
                            <div style={{ color: "#9C7B5C", float: "left", marginRight: "20px" }} onClick={openPage("Rejoco - Terms of Use", "/terms")}>
                                {"Terms of Use"}
                            </div>
                            */}


                    </div>
                    <div style={{ float: "right", marginRight: "10px", marginTop: "3px" }} >

                        <div style={{ color: "#9C7B5C", float: "left", marginRight: "30px" }} >
                            {"© " + (new Date().getFullYear()) + " Rejoco. All Rights Reserved"}
                        </div>



                    </div>


                </div>
            </div>
        </div>

    )
}




export default withRouter(AppInfoBox);
//formsy-react
//mdi-material-ui


import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

import TabContent from './components/TabContent';
import Login from './Login';
import Register from './Register';

export var PROVIDER_FACEBOOK = 'facebook';
export var PROVIDER_GITHUB = 'github';
export var PROVIDER_TWITTER = 'twitter';
export var PROVIDER_GOOGLE = 'google';



var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }



var styles = function styles(theme) {
  var _card;

  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    card: (_card = {
      flexGrow: 1
    }, _card[theme.breakpoints.up('sm')] = {
      flexBasis: '25rem',
      flexGrow: 0
    }, _card)
  };
};

var LoginRegister = (_temp = _class = function (_Component) {
  _inherits(LoginRegister, _Component);

  function LoginRegister(props) {
    _classCallCheck(this, LoginRegister);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.handleTabChange = function (event, value) {
      _this.setState({ tab: value });
      if(props.onTabChange){
        props.onTabChange(value)
      }

    };


    var tab = 0
    if(props.tabIndex >= 0){
      tab = props.tabIndex 
    }

    _this.state = {
      tab: tab
    };


    if(props.getTabIndexCallback){
      props.getTabIndexCallback(_this.handleTabChange)
    }
  

    return _this;
  }



  LoginRegister.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        transitionTimeout = _props.transitionTimeout,
        header = _props.header,
        footer = _props.footer,
        onLogin = _props.onLogin,
        onLoginWithProvider = _props.onLoginWithProvider,
        onRegister = _props.onRegister,
        onRegisterWithProvider = _props.onRegisterWithProvider,
        loginFailed = _props.loginFailed,
        registerFailed = _props.registerFailed,
        providers = _props.providers,
        disableLocal = _props.disableLocal,
        disableRegister = _props.disableRegister,
        disableRegisterProviders = _props.disableRegisterProviders;
    var tab = this.state.tab;



  

    var activeTab = void 0;
    switch (tab) {
      case 0:
        activeTab = React.createElement(
          TabContent,
          null,
          React.createElement(Login, { onLogin: onLogin,
            onLoginWithProvider: onLoginWithProvider,
            providers: providers,
            loginFailed: loginFailed,
            disableLocal: disableLocal,
            t:_props.t,
          })
        );
        break;

      case 1:
        activeTab = React.createElement(
          TabContent,
          null,
          React.createElement(Register, { onRegister: onRegister,
            onRegisterWithProvider: onRegisterWithProvider,
            providers: providers,
            registerFailed: registerFailed,
            disableLocal: disableLocal,
            disableRegisterProviders: disableRegisterProviders,
            t:_props.t,
          })
        );
        break;
    }

    return React.createElement(
      'div',
      { className: classes.root },
      React.createElement(
        Card,
        { className: classes.card },
        header && React.createElement(
          'div',
          null,
          header
        ),
        !disableLocal && !disableRegister && React.createElement(
          Tabs,
          {
            value: this.state.tab,
            onChange: this.handleTabChange,
            indicatorColor: 'primary',
            textColor: 'primary',
            variant: 'fullWidth'
          },
          React.createElement(Tab, { style:{ textTransform: "none" },label: 'Sign In' }),
          !disableRegister && React.createElement(Tab, {style:{ textTransform: "none" }, label: 'Create Account' })
        ),
        transitionTimeout > 0 ? React.createElement(
          Fade,
          { key: tab, 'in': true, timeout: transitionTimeout },
          activeTab
        ) : activeTab,
        footer && React.createElement(
          'div',
          null,
          footer
        )
      )
    );
  };

  return LoginRegister;
}(Component), _class.defaultProps = {
  transitionTimeout: 1000,
  providers: [PROVIDER_FACEBOOK, PROVIDER_GITHUB, PROVIDER_TWITTER, PROVIDER_GOOGLE]
}, _temp);
LoginRegister.propTypes = process.env.NODE_ENV !== "production" ? {
  transitionTimeout: PropTypes.number,
  header: PropTypes.element,
  footer: PropTypes.element,
  onLogin: PropTypes.func,
  loginFailed: PropTypes.string,
  registerFailed: PropTypes.string,
  onRegister: PropTypes.func,
  onLoginWithProvider: PropTypes.func,
  onRegisterWithProvider: PropTypes.func,
  providers: PropTypes.arrayOf(PropTypes.oneOf([PROVIDER_FACEBOOK, PROVIDER_GITHUB, PROVIDER_TWITTER, PROVIDER_GOOGLE])),
  disableLocal: PropTypes.bool,
  disableRegister: PropTypes.bool,
  disableRegisterProviders: PropTypes.bool
} : {};


export default withStyles(styles)(LoginRegister);
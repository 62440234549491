
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import ValidatedTextField from './components/ValidatedTextField';
import LoginRegisterError from "./components/LoginRegisterError";

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


var styles = function styles(theme) {
  return {
    root: {},
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    field: {
      marginTop: theme.spacing.unit
    },
    actions: {
      marginTop: theme.spacing.unit * 2
    }
  };
};

var LocalUserRegister = function (_Component) {
  _inherits(LocalUserRegister, _Component);

  function LocalUserRegister(props) {
    _classCallCheck(this, LocalUserRegister);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.disableSubmit = function () {
      _this.setState({ canSubmit: false });
    };

    _this.enableSubmit = function () {
      _this.setState({ canSubmit: true });
    };

    _this.submit = function (model) {
      if (_this.props.onRegister) {
        _this.props.onRegister(model);
      }
    };

    _this.state = {
      canSubmit: false
    };
    return _this;
  }

  LocalUserRegister.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        registerFailed = _props.registerFailed;
    var canSubmit = this.state.canSubmit;

    return React.createElement(
      'div',
      { className: classes.root },
      React.createElement(
        Formsy,
        { className: classes.form,
          onValid: this.enableSubmit, onInvalid: this.disableSubmit,
          onValidSubmit: this.submit },
        React.createElement(ValidatedTextField, {
          name: 'username',
          autoComplete: 'username',
          validations: 'minLength:3',
          validationErrors: {
            minLength: "Too short"
          },
          required: true,
          className: classes.field,
          label:  _props.t.EnterEMail 
        }),
        React.createElement(ValidatedTextField, {
          type: 'password',
          name: 'password',
          autoComplete: 'new-password',
          validations: 'minLength:2',
          validationErrors: {
            minLength: "Too short"
          },
          required: true,
          className: classes.field,
          label:  _props.t.EnterPassword 
        }),
        React.createElement(ValidatedTextField, {
          type: 'password',
          name: 'repeated_password',
          autoComplete: 'new-password',
          validations: 'equalsField:password',
          validationErrors: {
            equalsField: "Needs to be the same password as above"
          },
          required: true,
          className: classes.field,
          label: _props.t.EnterPasswordAgain  
        }),
        registerFailed && React.createElement(LoginRegisterError, { message: registerFailed }),
        React.createElement(
          'div',
          { className: classes.actions },
          React.createElement(
            Button,
            { 
              style:{ textTransform: "none" },
              type: 'submit',
              fullWidth: true,
              variant: 'contained', color: 'primary',
              disabled: !canSubmit },
              _props.t.Register
           
          )
        )
      )
    );
  };

  return LocalUserRegister;
}(Component);

LocalUserRegister.propTypes = process.env.NODE_ENV !== "production" ? {
  onRegister: PropTypes.func,
  registerFailed: PropTypes.string
} : {};


export default withStyles(styles)(LocalUserRegister);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import './notfound.css';
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const ComingSoonPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();



    var jobListingsButton =  null
    
    let backPageURL = useSelector(state => state.backPageURL)

    const handleCloseJobDetailsClick = () => {
        setTimeout(()=>{
            if (backPageURL=="") {
                
              
                window.scroll(0, 0);
                history.push("/");
                document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"

            }
            else {
               
                history.goBack()
                document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
            }
        },10)
       

    }



    if(backPageURL==""){
        jobListingsButton =  <Button style={{}} startIcon={<FormatListBulletedIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>All Job Listings</div></Button>
    }
    else if(backPageURL=="/"){
        jobListingsButton =  <Button style={{}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Job Listings</div></Button>
    }
    else {
        jobListingsButton =  <Button style={{}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Back</div></Button>
    }


    return (
        <div class="not-found inner-container" >
            <div style={{
                marginTop: "50px", width: "100%", height: "100%", textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "30px"
            }}>
                <br />

                <span style={{ fontSize: "30px", lineHeight: "30px", color: "#70757a" }}>{"Coming soon!"}</span>
                <br />
                <br />
                <br />
              
                <div style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "-20px", marginRight: "2px" }}><BuildIcon fontSize="small" style={{ paddingTop: "3px" }} /></div> <div style={{ display: "inline-block" }}>{"Stay Tuned for Updates!"}</div>

             
                <section class="text-container" style={{ marginTop: "70px" }}>
                    {jobListingsButton}
                </section>
                <div style={{ marginBottom: "110px", marginTop: "0px" }} />

            </div>
        </div>
    )
}





export default withRouter(ComingSoonPage);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { getJobPicture, getJobPortalName, getCompanyValues } from "../utils/Utils";
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const CompanySmall = (props) => {
    const { company, page, history,companyClick, ...other } = props;
    const itemRef = React.useRef();
    const dispatch = useDispatch();
    let websites = useSelector(state => state.websites);
    const [companyValues, setCompanyValues] = React.useState(getCompanyValues(websites, company));
    let loading = useSelector(state => state.loading);

    var handleCompanyClick = () =>{
        if(companyClick){
            companyClick(company)
        }
    }

    var descStyle = {}
    var imgStyle={}
    if (loading) {
        descStyle = { color: "#dedede" }
        imgStyle={opacity:0.5}
    }



    var companyItem = (
        <Paper className="companyitem" elevation={1} ref={itemRef} >
            <div class="companyitem_inner" onClick={handleCompanyClick}>
                <img class= "companyitem_img"  width={30} height={30} style={imgStyle} src={companyValues.logoURL}></img>
                <span class="companyitem_caption" style={descStyle}> {company.company}</span>
            </div>
           
        </Paper>
    )




    return  companyItem 
}



export default withRouter(CompanySmall);

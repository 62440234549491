import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import ItemList from "../components/ItemList";
import ItemSearch from "../components/ItemSearchField";
import ItemFilter from "../components/ItemFilter";
import ItemSort from "../components/ItemSort";
import {fetchData} from "../data/LoadItems";




import { startResizeService } from "../components/ResizeService";

import Company from "../components/Company";
import Item from "../components/Item";  
import { TrainRounded } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
//export default function 
import { NavLink, withRouter } from "react-router-dom";



import CompanyJobs from "../components/CompanyJobs"; 

import { getTabItemValueMap } from "../utils/Tabs"; 

const CompanyPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    /*
    useLayoutEffect(() => {
        var pathArray = window.location.pathname.split('/');
        var jobID = ""//pathArray[1]
        if(pathArray.length>=3){
            var jobID = pathArray[2]
        }
        startResizeService(dispatch)
        dispatch(({ type: "FETCH_DATA",initialize:true,jobID:jobID}))
    }, [])
    */




    let filterLeftValue              = useSelector(state => state.widthValues.filterLeft?state.widthValues.filterLeft.value:0)
    
 
    let backPageURL = useSelector(state => state.backPageURL)

    var company = useSelector(state => state.companyPageItem) 
    var jobPage = useSelector(state => state.jobPageItem) 

    
    if(!company){
        if(jobPage){
            if(jobPage.companyInfo){
                company = jobPage.companyInfo 
            }
            else{
                company = jobPage
            }
        }
    }
    

    var  [jobTitle, setJobTitle] = React.useState( '' );

    if(company&&!jobTitle){
        var title = "Rejoco - " + company.company
        document.title = title
        setJobTitle(title)

    }


    var title = ""
    var jobItemContainer = null
   
    var moreJobsAtCompany = ""




    if (company) {

        
        title = company.title
        var companyItem = <Company company={company} page={true}></Company>
       




        jobItemContainer = (<>
           {companyItem}
           <CompanyJobs company={company} moreJobs={false}/>
    

        </>)

        /*jobItemLinks = <Button component="a" href={openCompanyURL} variant="outlined" size="small" onClick={(event) => {event.preventDefault(); event.stopPropagation()}} style={{marginTop:"1px",marginRight:"20px",float:"left"}} >{job.company}</Button>*/

    }



    const handleCloseCompanyDetailsClick = (event,showAllCompanies) => {
        setTimeout(()=>{
            if (backPageURL=="" || showAllCompanies) {
                if(company){
                    company.hideDetailsFast = false
                }
              
                window.scroll(0, 0);
                history.push("/companies");
                document.title = "Rejoco - Companies - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"

            }
            else {
                if(company){
                    company.hideDetailsFast = false
                }
                history.goBack()
               // document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
            }
        },10)
       

    }


    console.log(window.location)


    var backButton =  null
    var allCompaniesButton = null
    var tabItemValueMap = getTabItemValueMap()
    var companyTab = tabItemValueMap["companies"]
    if(companyTab && backPageURL!="/companies"){
        allCompaniesButton =   <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<FormatListBulletedIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseCompanyDetailsClick(event,true)}} ><div style={{marginTop:"1px"}}>All Companies</div></Button>
    }
    
    if(backPageURL==""){

    }
    else if(backPageURL=="/"){
        backButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseCompanyDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Job Listings</div></Button>
    }
    else {
        backButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseCompanyDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Back</div></Button>
    }


    return (
        <div class="inner-container">
            {/*<ItemSearch jobPage={true}/>*/}

            <div style={{ marginTop: "15px", marginLeft: "10px" }}>
                {backButton}
                {allCompaniesButton}
            </div>
            <div style={{ "clear": "both" }}></div>
            <div style={{ marginBottom: "110px", marginTop: "0px" }}>
                {jobItemContainer}

            </div>

        </div>
    )
}




export default withRouter(CompanyPage);
import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import FilterAutocompleteInput from "./FilterAutocompleteInput";


import AppsIcon from '@material-ui/icons/Apps';



import IconText from "./IconText"
//  {<IconText text={"Category"} icon={<AccountTreeIcon fontSize="small" />}/>}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  accordionBox: {
    marginLeft: "-13px",
    marginRight: "-13px",
    paddingBottom: "1px"
  },
  accordionBoxLast: {
    marginLeft: "-13px",
    marginRight: "-13px",

  },


  accordion: {
    marginLeft: "-13px!important",
    marginRight: "-13px!important",
    paddingBottom: "1px",
    borderTop: "1px solid #EEEEEE"

  },
  activeFilters: {
    minHeight: "20px",
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: theme.typography.pxToRem(14),
  }

}));



export default function FilterCategory(props) {
  const { accordionClassName, showInDialog, ...other } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  var accordionClass = classes.accordion
  if (accordionClassName) {
    accordionClass = accordionClassName
  }

  var onInputChange = (event, value, reason) => {
    
  }


  var onChange = (event, newValue) => {

    var catLength = newValue.length;
    for (var i = 0; i < catLength; i++) {
        var value = newValue[i]

        if (typeof value === 'string') {
            newValue[i] = { id:value.toLowerCase(), title: value, checked: true, visible: true }
        }
    }

    dispatch(({ type: "SET_SELECTED_CATEGORIES", selectedValues: newValue }))
  }

  return (
    <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >

        <Typography className={classes.heading}>{<IconText text={"Category"} icon={<AppsIcon fontSize="small" />}/>}</Typography>
      </AccordionSummary>
      <AccordionDetails>

        <div style={{ marginBottom: "6px", width: "100%" }}>

          <FilterAutocompleteInput
            showInDialog={showInDialog}
            onInputChange={onInputChange}
            onChange={onChange}
            arrayName="categories"
            selectedArrayName="selectedCategories"
            placeholderName="Category" 
            acceptfreeSolo={true}
            
            />
        </div>

      </AccordionDetails>
    </Accordion>


  );
}


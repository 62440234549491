import React, { useEffect, useLayoutEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable jsx-a11y/anchor-is-valid */

import Link from '@material-ui/core/Link';
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const BlobImage = (props) => {
    const { src,width,height,...other } = props;
    const dispatch = useDispatch();

    var width5 = width*5
    var height5 = height*5

    let imgSrc = useSelector(state => state.images[src])

    const imgElement = useRef(null)

    function drawImageScaled(img, ctx) {
        var canvas = ctx.canvas ;
        var hRatio = canvas.width  / img.naturalWidth    ;
        var vRatio =  canvas.height / img.naturalHeight  ;
        var ratio  = Math.min ( hRatio, vRatio );
        var centerShift_x = ( canvas.width - img.naturalWidth*ratio ) / 2;
        var centerShift_y = ( canvas.height - img.naturalHeight*ratio ) / 2;  
        ctx.clearRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(img, 0,0, img.naturalWidth, img.naturalHeight,
                           centerShift_x,centerShift_y,img.naturalWidth*ratio, img.naturalHeight*ratio);  
     }

    function convertImgToBase64URL(url, callback, outputFormat){
        var img = imgElement.current
        if(img){
            img.crossOrigin = 'Anonymous';
            var onLoad = function(){
                if(img.src==url){
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    canvas.height = 90;
                    canvas.width = 90;
                    drawImageScaled(img,ctx)
                    try{
                        canvas.toBlob(function(blob) {
                            callback(URL.createObjectURL(blob));
                        })
                    }
                    catch(e){
                        console.log(e)
                    }
                
                    canvas = null; 
                }
            };
            if(img.complete && img.naturalHeight !== 0){
                onLoad()
                
            }
            else{
                img.onload = onLoad
            }
        }
        else{
            setTimeout(()=>{
                convertImgToBase64URL(url, callback, outputFormat)
            })
        }
        
    }

    useLayoutEffect(() => {
        if(!imgSrc){
            convertImgToBase64URL(src, function(dataURL){
                if(imgElement.current){
                    imgElement.current.src = dataURL
                }
                dispatch({ type: "SET_IMAGE_BLOB", src:src,blob:dataURL})
            })
        }
       
      }, [])
   

    var source = ""
    if(imgSrc){
        return (
            <img width={width} height={height} src={imgSrc}></img>
        )
    } 
    else{
        return (
            <img ref={imgElement} width={width} height={height} style={{ objectFit: "contain", width: width+"px", height: height+"px"}} src={src}  ></img>
        )
    }
  

   
    

        
}




export default BlobImage;
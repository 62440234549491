import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FacebookButton, GithubButton, TwitterButton, GoogleButton } from "./components/ProviderButtons";
import { PROVIDER_FACEBOOK, PROVIDER_GITHUB, PROVIDER_GOOGLE, PROVIDER_TWITTER } from "./";

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }



var styles = function styles(theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      "& > *+*": {
        marginTop: theme.spacing.unit
      }
    }
  };
};

var ProviderChoices = function (_Component) {
  _inherits(ProviderChoices, _Component);

  function ProviderChoices() {
    var _temp, _this, _ret;

    _classCallCheck(this, ProviderChoices);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.handleClick = function (providerId) {
      if (_this.props.onChoice) {
        _this.props.onChoice(providerId);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  ProviderChoices.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        login = _props.login,
        register = _props.register,
        providers = _props.providers;


    var commonProps = {
      login: login,
      register: register,
      variant: 'contained',
      color: 'primary',
      className: classes.button,
      onClick: this.handleClick
    };

    return React.createElement(
      'div',
      { className: classes.root },
      providers.includes(PROVIDER_FACEBOOK) && React.createElement(FacebookButton, commonProps),
      providers.includes(PROVIDER_GITHUB) && React.createElement(GithubButton, commonProps),
      providers.includes(PROVIDER_TWITTER) && React.createElement(TwitterButton, commonProps),
      providers.includes(PROVIDER_GOOGLE) && React.createElement(GoogleButton, commonProps)
    );
  };

  return ProviderChoices;
}(Component);

ProviderChoices.propTypes = process.env.NODE_ENV !== "production" ? {
  login: PropTypes.bool,
  register: PropTypes.bool,
  onChoice: PropTypes.func,
  providers: PropTypes.arrayOf(PropTypes.string)
} : {};


export default withStyles(styles)(ProviderChoices);
import React from 'react';

import { useSelector, useDispatch } from 'react-redux'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import Box from '@material-ui/core/Box';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { StyledTab, StyledTabs, StyledTabVertical,tabItemValues } from "../utils/Tabs"; 

import { useHistory } from "react-router-dom";

import { NavLink, withRouter } from "react-router-dom";

import MenuIcon from '@material-ui/icons/Menu';

import image1 from '../images/large.png';
import image2 from '../images/tropical.png';


import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));





function AppBarBox(props) {
  const { history, ...other } = props;
  const dispatch = useDispatch();




  const classes = useStyles();

  let appBarSizeValue              = useSelector(state => state.widthValues.appBar?state.widthValues.appBar.value:0)
  let appBarMiniSizeValue              = useSelector(state => state.widthValues.appBarMini?state.widthValues.appBarMini.value:0)
  


  var tabsVisible = appBarSizeValue == 1
  var singleTabVisible = appBarMiniSizeValue==1


  

  let tabIndex = useSelector(state => state.tabIndex)

  let visitedPaths = useSelector(state => state.visitedPaths)

  let sessionStartTime = useSelector(state => state.sessionStartTime)

  let  logoMiniValue              = useSelector(state => state.widthValues. logoMini?state.widthValues. logoMini.value:0)

  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer = ( open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen(open );
  };


  var tabs = tabItemValues

  const handleTabChange = (event, newValue) => {
    if (!newValue && newValue != 0) {
      newValue = tabIndex
    }


    //if (newValue !== tabIndex) {


      var newTab = tabs[newValue]
      var noReload = ""

      //if (visitedPaths[newTab.path]) {
        noReload = "NODATARELOAD_" + sessionStartTime
      //}

      if (newTab) {
        if (newTab.path) {
          history.push(newTab.path, noReload);
          window.scroll(0, 0);
        }
        if (newTab.title) {
          document.title = newTab.title
        }
      }


   // }

    if(!tabsVisible){
      setMenuOpen(false );
    }

  };


  let jobsLoaded = useSelector(state => state.jobsLoaded)

  const handleJobDetailsClick = () => {
    document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    window.scroll(0, 0);
    history.push("/");



  }
  var image1Path = image1.replace("./", "/")
  var image2Path = image2.replace("./", "/")










  var tabPanel = null
  var menuButton = null
  var drawerItems = null


  


  if (tabsVisible) {
    var tabItems = tabs.map((tab, index) => {
      return (
        //<Tooltip disableHoverListener={false} disableFocusListener disableTouchListener title={tab.label} interactive>
        <StyledTab onMouseEnter={()=>{/*show menu */}} label={tab.label} icon={tab.icon} {...a11yProps(index)} />
       // </Tooltip>
      )
      
    })
    tabPanel =
      <div style={{marginRight:"10px"}}>
        <StyledTabs
        size="small"
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable"

        indicatorColor="primary"
        textColor="primary"
        aria-label="tabs"

      >

        {tabItems}




      </StyledTabs>
      </div>
  }
  else{
    if(singleTabVisible){

    
      var tabItems = tabs.map((tab, index) => {
        if(index==tabIndex ){
          return  (
             // <Tooltip title={tab.label} interactive>
                      <StyledTab label={tab.label} icon={tab.icon} {...a11yProps(index)} />
             // </Tooltip>
  
            )
        }
        else{
          return <div></div>
        }
       
      })
      
      tabPanel =
        <div style={{float:"left" ,marginLeft:"-100px"}}>
          <StyledTabs
            size="small"
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            aria-label="single_tab"
  
          >
            {tabItems}
          </StyledTabs>
        </div>
    }
   




    menuButton = 
    <div style={{float:"left",whiteSpace: "nowrap",marginRight:"20px",marginTop:"20px"}}>
      
      {/*<div style={{color:"#705741", marginRight: "60px", marginTop: "12px", float: "left" }}>
        <div style={{  float: "left",marginRight: "10px"}}>
          {tabs[tabIndex].icon}
        </div>
        <div style={{  }}>
          {tabs[tabIndex].label}

        </div>

  </div>*/}

    <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
      >
       <MenuIcon/>
      </IconButton>
      </div>
    

    var tabItemsVertical = tabs.map((tab, index) => {
      return <StyledTabVertical label={<div style={{width:"150px"}}><div><div style={{float:"left",marginRight:"10px"}}>{tab.icon}</div><div style={{float:"left"}} >{tab.label}</div></div> </div>}  {...a11yProps(index)} />
    })
  

    drawerItems =

      <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
        <div style={{minWidth:"150px",marginRight:"20px"}}>
        <StyledTabs
          size="small"
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          orientation="vertical"
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"

        >

          {tabItemsVertical}


          </StyledTabs>
        </div>
      </Drawer>

    
  }


  var descSubtitle = ""
  if(logoMiniValue==1){
    descSubtitle = "The #1 AI-Powered Remote Job Finder"
  }
  else{
    descSubtitle = "The #1 Remote Job Finder"
  }

  return (

    <header className="App-bar">

      <div className="wrapper">

        <div className="container" >
          <div className="App-bar-items" >
            <div style={{whiteSpace: "nowrap", width: "200px", cursor: "pointer" }} onClick={handleJobDetailsClick} >
              <div className="App-logo" style={{}} >

                <img title="Rejoco - Remote Job Finder" src={image1Path} className="App-logo-image" />
                <img title="Rejoco - Remote Job Finder" src={image2Path} className="App-logo-icon" />

              </div>

              <div className="App-desc" style={{}} >
                {descSubtitle}
              </div>
            </div>
            <div style={{ whiteSpace: "nowrap",marginRight: "-5px", paddingTop: "1px", left: "0px" }}>
              {tabPanel}
              {menuButton}
              {drawerItems}

            </div>

          </div>
        </div>
      </div>

      <div style={{position:"absolute",right:"2px",top:"1px",marginRight:"1px",fontSize: "10px"}}>
      <span style={{color:"#000000",opacity:0.25}}> [Public Beta]</span>
      </div>


    </header>

  );

}
export default withRouter(AppBarBox);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const SimpleContentPage = (props) => {
    const { history,content, ...other } = props;
    const dispatch = useDispatch();

    let backPageURL = useSelector(state => state.backPageURL)

    const backClick = (event,showAllCompanies) => {
        setTimeout(()=>{
            if (backPageURL=="") {
                window.scroll(0, 0);
                history.push("/");
                document.title = "Rejoco - Companies - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
            }
            else {
                history.goBack()
            }
        },10)
       

    }
    var backButton = null
    if(backPageURL==""){
        backButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<FormatListBulletedIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {backClick(event)}} ><div style={{marginTop:"1px"}}>Job Listings</div></Button>
    }
    else {
        backButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {backClick(event)}} ><div style={{marginTop:"1px"}}>Back</div></Button>
    }
    return (
        <div class="inner-container" >
            <div style={{ marginTop: "15px", marginLeft: "10px" }}>
                {backButton}
            </div>
            <div style={{ "clear": "both" }}></div>
            <div style={{ marginTop: "15px", marginLeft: "10px",marginRight: "10px"}}>
            {content}
            </div>

            <div style={{ marginBottom: "110px", marginTop: "0px" }} />
        </div>
    )
}



export default withRouter(SimpleContentPage);

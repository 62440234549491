import React, { useEffect, useLayoutEffect } from 'react';



//export default function 

const enableIcons = true

const IconText = (props) => {
    

    if(enableIcons){

        var marginTop = 1
        
        if(props.yOffset){
            marginTop = marginTop+props.yOffset
        }


        return (
            <>
            <div style={{opacity:0.9,width:"27px",display:"inline-block",verticalAlign: "middle",marginRight:"5px",marginTop:marginTop+"px",marginBottom:"-5px"}}>{props.icon}</div><div style={{display:"inline-block",verticalAlign: "middle",marginTop:"5px",marginTop:"2px",fontWeight:500,textShadow: "1px 0px #E5E5E5"}}>{props.text}</div>
            </>
        )
    }
    else{
        return props.text
    }
    
}




export default IconText;
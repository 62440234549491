import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";


import AppBarBox from "../components/AppBarBox.js";
import AppInfoBox from "../components/AppInfoBox.js";
import Popups from "../components/Popups.js"


const WebPage = (props) => {
    const { content,...other } = props;
    const dispatch = useDispatch();

    return (
        <div className="App" >

            <AppBarBox/>
           
            <div className="content-wrapper">

                <div className="container" style={{ backgroundColor: "#f7f7f7" }}> {/*f5f5f5*/}

                    {content}

                </div>
            </div>

            <Popups/>

            <AppInfoBox/>

        </div>
    )
}




export default WebPage;
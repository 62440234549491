
import { SignalCellularNull } from '@material-ui/icons';
import { updateStateData } from '../data/data'

import { getTabIndex } from "../utils/Tabs"; 

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;






let defaultStateValues = {
    oneMonthAgo:new Date(),
    selectedRatingTextValues: {
        '0': "All Ratings",
        '1': "1 Star & Up",
        '2': "2 Stars & Up",
        '3': "3 Stars & Up",
        '4': "4 Stars & Up",
    },
    selectedDateTextValues: {
        '1': ["Past  24 hours",86400000],
        '2': ["Past week", 1206000000],//604800000],
        '3': ["Past month",2629746000],
        '4': ["Past 3 months",7889238000],
        '5': ["Past 6 months",15778476000],
        '6': ["Past year",31556952000],
        '7': ["Custom Date",-1],
    },
    selectedCompanySizeTextValues:{
        '0': "All Companies Sizes",
        '1': "1-200 employees",
        '2': "201-500 employees",
        '3': "501-1000 employees",
        '4': "1001-5000 employees",
        '4': "5001+ employees",
    },

  
    websites: [
        { id: "rejoco", title: "Rejoco", counter: 0, url: "https://www.rejoco.com/", checked: true, jobSource: true }
    ],

    languages: [
        /*{id:"all", title: "All", checked:true,visible:true,defaultValue:true},*/
        { id: "german", title: "German", checked: true, visible: true },
        { id: "english", title: "English", checked: true, visible: true },
        { id: "french", title: "French", checked: true, visible: true },
        { id: "spanish", title: "Spanish", checked: true, visible: true },
    ],

 /*{id:"all", title: "All", checked:true,visible:true,defaultValue:true},*/
    categories: [],
    /*
    [
       
        { id: "softwaredevelopment", title: "Software Development", checked: true, visible: true },
        { id: "design", title: "Design", checked: true, visible: true },
        { id: "support", title: "Support", checked: true, visible: true },
        { id: "sales", title: "Sales", checked: true, visible: true },
        { id: "writing", title: "Writing", checked: true, visible: true },
        { id: "product", title: "Product", checked: true, visible: true },
        { id: "legal", title: "Legal", checked: true, visible: true },
        { id: "finance", title: "Finance", checked: true, visible: true },
        { id: "marketing", title: "Marketing", checked: true, visible: true },
        { id: "dataentry", title: "Data Entry", checked: true, visible: true },
        { id: "healthcare", title: "Healthcare", checked: true, visible: true },
        { id: "recruiter", title: "Recruitment", checked: true, visible: true },
        { id: "teaching", title: "Teaching", checked: true, visible: true },
        { id: "virtualassistant", title: "Virtual Assistant", checked: true, visible: true },
        { id: "other", title: "Others", checked: true, visible: true }
    ],
    */

    globalTimezones: [
        { id: "africa", title: "Africa", checked: true, visible: true },
        { id: "asia", title: "Asia", checked: true, visible: true },
        { id: "europe", title: "Europe", checked: true, visible: true },
        { id: "northamerica", title: "North America", checked: true, visible: true },
        { id: "pacific", title: "Pacific", checked: true, visible: true },
        { id: "southamerica", title: "South America", checked: true, visible: true },
    ],

    salaryValues:[0,200001]
}

defaultStateValues.allTimezones = [...defaultStateValues.globalTimezones,
{ id: "germany", title: "Germany", checked: true, visible: true },
{ id: "usa", title: "USA", checked: true, visible: true },
{ id: "canada", title: "Canada", checked: true, visible: true },
{ id: "spain", title: "Spain", checked: true, visible: true },
{ id: "uk", title: "UK", checked: true, visible: true },
{ id: "france", title: "France", checked: true, visible: true },
{ id: "other", title: "Other", checked: true, visible: true },
]

defaultStateValues.oneMonthAgo.setMonth(defaultStateValues.oneMonthAgo.getMonth() - 1);
defaultStateValues.oneMonthAgo.setHours(0, 0, 0);
defaultStateValues.oneMonthAgo.setMilliseconds(0);



var initalTabIndex = getTabIndex(window.location.pathname)


// window.initialLoadState

const initalState = {
    user:"",
    token:"",
    loggingIn:false,
    loginRegisterTab:0,
    popupOpen:"",
    sessionStartTime:(new Date().getTime()),
    visitedPaths:{},
    tabIndex:initalTabIndex,
    backPageURL:"",
    history:[],
    searchYScroll:null,
    jobsLoaded:false,
    companyPageItem:null,//todo
    jobPageItem:null,
    jobSelected:null,
    jobLastClicked:null,
    jobSelectedCounter:1,
    browserTimezone:tz,
    sortMode:"Date",
    sortSavedMode:"DateAdded",
    searchValue:"",
    searchValueText:"",
    companySearchValue:"",
    companySearchValueText:"",
    jobs:{},
    jobList:[],
    savedJobs:[],
    similarJobs:[],
    completelyLoadedJobCompanies:{},
    completelyLoadedCompanies:{},

    companies:{},
    companyList:[],
    companySearchList:[],
    visibleJobsNum: 30,

    visibleCompaniesNum:60,


    selectedSalaryRange:"year",
    selectedSalaryValues:[...defaultStateValues.salaryValues],
    changeSelectedSalaryValueText:false,

    categories:defaultStateValues.categories,
    selectedCategories:[],

    timezones:defaultStateValues.globalTimezones,
    selectedTimezones:[],

    languages:defaultStateValues.languages,
    selectedLanguages:[],


    websites: defaultStateValues.websites,
    timezoneInputState:"empty",

    loading:true,
    loadCounter:1,
    widthValues:{},
    heightValues:{},
    
    selectedRating:'0',
    selectedRatingText:defaultStateValues.selectedRatingTextValues['0'],
    selectedDate:null ,
    selectedDateValue:defaultStateValues.oneMonthAgo,
    selectedDateText:"",
    selectedDateRange:0,

    selectedCompanySize:'0',

 
    itemsNeedReload:true,
    jobFilterDialogOpen:false,
    values:defaultStateValues,
    images:{},

}




const removeFilterActions = {

    selectedCategories: (state, action,updateData,removeAll) => {
        var removed = false
        var selectedCategories = []
        if (removeAll) {
            removed = true
        }
        else {
            selectedCategories = state.selectedCategories
            selectedCategories = selectedCategories.filter(item => {
                if (item.id != action.id) {
                    return true
                }
                else {
                    removed = true
                }
            })
        }


        if (removed) {
            updateData.reload = true
            return { ...state, selectedCategories: selectedCategories }
        }
        else {
            return state
        }
    },

    selectedTimezones: (state, action, updateData, removeAll) => {
        var removed = false
        var selectedTimezones = []
        if(removeAll){
            removed = true
        }
        else{
            selectedTimezones = state.selectedTimezones 
            selectedTimezones = selectedTimezones.filter(item => {
                if (item.id != action.id) {
                    return true
                }
                else{
                    removed = true
                }
            })

        }
        if(removed){
            updateData.reload=true
            return  { ...state, selectedTimezones: selectedTimezones }
        }
        else{
            return state
        }
    },
    selectedLanguages: (state, action,updateData,removeAll) => {
        var removed = false

        var selectedLanguages = []
        if(removeAll){
            removed = true
        }
        else{
            selectedLanguages = state.selectedLanguages 
            selectedLanguages = selectedLanguages.filter(item => {
                if (item.id != action.id) {
                    return true
                }
                else{
                    removed = true
                }
            })
        }
        if (removed) {
            updateData.reload = true
            return { ...state, selectedLanguages: selectedLanguages }
        }
        else {
            return state
        }

    },
    selectedRating: (state, action,updateData,removeAll) => {
        var removed = false
        var selectedRatingText = state.values.selectedRatingTextValues['0']
        if(state.selectedRating!='0'){
            updateData.reload=true
            return  { ...state, selectedRatingText: selectedRatingText, selectedRating: '0' }
        }
        else{
            return state
        }
      
    },
    selectedSalary: (state, action,updateData,removeAll) => {
        
        var salaryValues = state.selectedSalaryValues
        var salaryValuesDefault = state.values.salaryValues
        if( (salaryValues[0] != salaryValuesDefault[0] && salaryValues[0] != salaryValuesDefault[1]) || 
        (salaryValues[1] != salaryValuesDefault[0] && salaryValues[1] != salaryValuesDefault[1])){
            updateData.reload=true
            return  { ...state, selectedSalaryValues: [...state.values.salaryValues], changeSelectedSalaryValueText: true }
        }
        else{
            return state
        }
    },
    selectedDate: (state, action,updateData,removeAll) => {
        var selectedRatingText = ""
        var selectedDateRange = 0
        if(state.selectedDateRange!=0){
            updateData.reload=true
            return  { ...state, selectedDateRange: selectedDateRange, selectedRatingText: selectedRatingText, selectedDate: null }
        }
        else{
            return state 
        }
        
    },
    selectedCompanySize: (state, action,updateData,removeAll) => {
        if(state.selectedCompanySize!=="0" && state.selectedCompanySize!==0){
            updateData.reload=true
            return  { ...state, selectedCompanySize: "0" }
        }
        else{
            return state 
        }
    },

    selectedWebsites: (state, action,updateData,removeAll) => {
        var removed = false
        var websites = [...state.websites]
        websites.forEach(website => {
            if (website.title !== "Rejoco") {
                if(!website.checked){
                    removed = true
                }
                website.checked = true
            }
        })
        if (removed) {
            updateData.reload=true
            return  { ...state, websites: websites }
        }
        else{
            return state
        }
    }
}



const jobStore = (state = initalState, action) => {
    var updateData = {reload:false}
    var newState = state
    var updateStateDataAction = {...action}

    switch (action.type) {
        case "SET_IMAGE_BLOB":
            var images = state.images
            images[action.src] = action.blob
            newState = {...state,images: images }
            break;
        case "SET_POPUP_STATE":
            newState = {...state,popupOpen: action.value }
            break;
        case "SET_LOGIN_REGISTER_TAB":
            newState = {...state,loginRegisterTab: action.value }
            break;
        case "LOGIN":
            newState = {...state,loggingIn:true}
            updateData = { ...updateData, reload: true }
            break;
        case "REGISTER":
            newState = {...state,loggingIn:true}
            updateData = { ...updateData, reload: true }
            break;
        case "SET_TAB_INDEX":
            if (state.tabIndex != action.tabIndex) {
                newState = { ...state, tabIndex: action.tabIndex }
            }
            break;
        case "RESIZE":

            var heightValues = state.heightValues
            var widthValues = state.widthValues

            if(action.widthValues){
                widthValues = action.widthValues
            }
            
            if(action.heightValues){
                heightValues = action.heightValues
            }


            newState = {...state,widthValues:widthValues,heightValues:heightValues}
            break;
        case "HISTORY":
            var visitedPaths = state.visitedPaths
            var historyList = state.history
            console.log("BEFORE: "+JSON.stringify(historyList))
            if(action.action=="POP"){
                if(historyList.length>0){
                    if(state.backPageURL===action.location){
                        historyList.pop()
                    }
                    else{
                        historyList.push(action.location)
                    }
                    
                }
                
              
            }
            else if(action.action=="PUSH"){
                historyList.push(action.location)
            }
            else if(action.action=="REPLACE"){
                if(historyList.length>0){
                    historyList.pop()
                }
                historyList.push(action.location)
            }
            var backPageURL = ""
            if(historyList.length>1){
                backPageURL = historyList[historyList.length-2]
            }
            console.log("AFTER: "+JSON.stringify(historyList))
            console.log("backPageURL: "+backPageURL)
     
            visitedPaths[action.location] = true


            newState = {...state,history:historyList,backPageURL:backPageURL,visitedPaths:visitedPaths}
            break;
        case "FETCH_DATA":
            var jobList = state.jobList
            var loadCounter = state.loadCounter
            if(action.clear){
                jobList = []
            }

            var loading = true
            if(action.hideLoading){
                loading = state.loading
            }
            var jobPageItem = state.jobPageItem
            var companyPageItem = state.companyPageItem
            var reload = true
            if(updateStateDataAction.jobID){
                var loadJob = state.completelyLoadedJobCompanies[updateStateDataAction.jobID]
                if(loadJob){
                    jobPageItem = loadJob
                    updateStateDataAction.jobID = null 
                    reload = false
                }
            }
         
            if(updateStateDataAction.companyID){
                var loadCompany = state.completelyLoadedCompanies[updateStateDataAction.companyID]
                if(loadCompany){
                    companyPageItem = loadCompany
                    updateStateDataAction.companyID = null 
                    reload = false
                }
            }
            if(!reload){
                loading = false
            }
            else{
                loadCounter++
            }

            updateData = {...updateData,reload:reload}
            newState = { ...state,companyPageItem:companyPageItem,jobPageItem:jobPageItem,jobList:jobList, loadCounter: loadCounter,loading: loading }
        
            break;
        case "TOOGLE_ITEM_SAVE":

            if(state.user){
                var jobs = state.jobs
                var savedJobs = state.savedJobs
                var jobLastClicked = state.jobLastClicked
                var saved = null
                var savedJob= null
                for (const [jobID, item] of Object.entries(jobs)) {
                    if(item.slug==action.job.slug){
                        item.saved = !item.saved
                        saved = item.saved
                        savedJob = item
                        jobLastClicked = item
                        break;
                    }
                }
                if(savedJob != null){

                


                    if(saved){
                        savedJobs.unshift(savedJob)
                    }
                    else{
                        savedJobs = savedJobs.filter(item=>{
                            if(item.slug===savedJob.slug){
                                return false
                            }
                            else{
                                return true
                            }
                        })
                    }
                }


                newState = { ...state, savedJobs:savedJobs,jobs:jobs,jobLastClicked:jobLastClicked,jobSelectedCounter:state.jobSelectedCounter+1 }
            }
            else{
                newState = { ...state, popupOpen:"loginRegister"}
            }
            break;

        case "UPDATE_ITEM_LIST":
            newState = { ...state, jobSelectedCounter:state.jobSelectedCounter+1 }
            break;

        case "TOOGLE_ITEM_OPEN":
            var jobs = state.jobs
            var jobSelected = state.jobSelected
            var jobLastClicked = state.jobLastClicked
            for (const [jobID, item] of Object.entries(jobs)) {
                if (item.slug == action.job.slug) {

                    item.open = !item.open

                    if (action.itemHeight) {
                        item.itemHeight = action.itemHeight
                    }

                    if (item.open) {
                        jobSelected = item
                        item.hideDetailsFast = false

                    }
                    else {
                        item.hideDetailsFast = action.hideDetailsFast
                        jobSelected = null
                    }
                    jobLastClicked = item
                    break;
                }
            }

            newState = { ...state, jobs:jobs,jobSelected:jobSelected,jobLastClicked:jobLastClicked,jobSelectedCounter:state.jobSelectedCounter+1 }
            break;
        case "SET_FILTER_DIALOG_OPEN":

            newState = { ...state, jobFilterDialogOpen:action.value }

            if(state.itemsNeedReload && action.value==false){
                state.itemsNeedReload = false
                newState.loading = true
                updateData = {...updateData,reload:true}
            }


            break;
        case "SET_COMPANY_SEARCH_VALUE":


            var companySearchValueText = state.companySearchValueText
            if (action.startCompanySearch) {
                companySearchValueText = action.companySearchValue
            }

            newState = { ...state, companySearchValue: action.companySearchValue,companySearchValueText:companySearchValueText}
            if (action.startCompanySearch) {
                newState.loading = true
                newState.loadCounter=  newState.loadCounter+1 
                updateData = { ...updateData, reload: true }
            }
            break;

        case "SET_SEARCH_VALUE":

            var searchValueText = state.searchValueText
            if (action.startSearch) {
                searchValueText = action.searchValue
            }


            newState = { ...state, searchValue: action.searchValue,searchValueText:searchValueText}
            if (action.startSearch) {
                newState.loading = true
                newState.loadCounter=  newState.loadCounter+1 
                updateData = {...updateData,reload:true}
            }
            break;

        case "REMOVE_ALL_ACTIVE_FILTERS":

            for (const [key, removeFunc] of Object.entries(removeFilterActions)) {
                if (typeof removeFunc === 'function') {
                    var newStateRemoved = removeFunc(newState, action, updateData,true)
                    if(newStateRemoved){
                        newState = newStateRemoved
                    }
                }
            }
            if (updateData.reload == true) {
                newState = { ...newState, loading: true, loadCounter: state.loadCounter + 1 }
            }
            break;
        case "REMOVE_ACTIVE_FILTER":

            var removeFunc = removeFilterActions[action.filterType]
            if (typeof removeFunc === 'function') {
                var newStateRemoved = removeFunc(state, action, updateData,false)
                if (newStateRemoved) {
    
                    newState = newStateRemoved
                }
            }

            if (updateData.reload == true) {
                newState = { ...newState, loading: true, loadCounter: state.loadCounter + 1 }
            }


            break;
       

        case "SET_SELECTED_DATE_VALUE":
            var changed = false
            var loadCounter = state.loadCounter

            if (action.selectedValue.getTime && !isNaN(action.selectedValue.getTime())) {
                if(!state.selectedDateValue.getTime || action.selectedValue.getTime() != state.selectedDateValue.getTime()){
                    changed = true
                    loadCounter = state.loadCounter + 1
                    updateData = { ...updateData, reload: true }
                }
            }

            var selectedDateText = ""
            var selectedDateRange = 0
            if(state.selectedDate){
                if(state.selectedDate=="7" && action.selectedValue.getTime && !isNaN(action.selectedValue.getTime()) ){
                    selectedDateText = "Since "+action.selectedValue.toLocaleDateString("de-DE")
                    selectedDateRange = (new Date).getTime()-action.selectedValue.getTime()
                }
                else{
                    selectedDateText  = state.values.selectedDateTextValues[state.selectedDate][0]
                    selectedDateRange = state.values.selectedDateTextValues[state.selectedDate][1]
                }

            }
            
            newState = { ...state, selectedDateValue: action.selectedValue,selectedDateRange:selectedDateRange,selectedDateText:selectedDateText, loading: changed, loadCounter: loadCounter }

            break;
        case "SET_SELECTED_DATE":

            var changed = true
            var loadCounter = state.loadCounter + 1
            updateData = { ...updateData, reload: true }


            var selectedDateText = ""
            var selectedDateRange = 0
            if(action.selectedValue){
                if(action.selectedValue=="7" && state.selectedDateValue.getTime && !isNaN(state.selectedDateValue.getTime())){
                    selectedDateText = "Since "+state.selectedDateValue.toLocaleDateString("de-DE")
                    selectedDateRange = (new Date).getTime()-state.selectedDateValue.getTime()
                }
                else{
                    selectedDateText  = state.values.selectedDateTextValues[action.selectedValue][0]
                    selectedDateRange = state.values.selectedDateTextValues[action.selectedValue][1]
                }
                

            }
           
            newState = { ...state,  selectedDate: action.selectedValue,selectedDateRange:selectedDateRange,selectedDateText:selectedDateText, loading: changed, loadCounter: loadCounter }

            break;
         case "SET_SELECTED_COMPANY_SIZE":

            var changed = true
            var loadCounter = state.loadCounter + 1
            updateData = { ...updateData, reload: true }

            newState = { ...state,  selectedCompanySize: action.selectedValue, loading: changed, loadCounter: loadCounter }

            break;
        case "SET_SELECTED_SALARY_RANGE":
            newState = { ...state, selectedSalaryRange: action.selectedValue,changeSelectedSalaryValueText:false }

            break;
        case "SET_SELECTED_SALARY_VALUES":


            newState = { ...state, selectedSalaryValues: action.selectedValues,changeSelectedSalaryValueText:false }

            break;


        case "SET_SELECTED_RATING":

            var changed = true
            var loadCounter = state.loadCounter+1
            updateData = { ...updateData, reload: true }

            var selectedRatingText = state.values.selectedRatingTextValues[action.selectedValue]

            newState = { ...state,selectedRatingText:selectedRatingText, selectedRating: action.selectedValue, loading: changed, loadCounter: loadCounter }

            break;
        case "SET_SELECTED_CATEGORIES":

            var changed = true
            var loadCounter = state.loadCounter+1
            updateData = { ...updateData, reload: true }
            newState = { ...state, selectedCategories: action.selectedValues, loading: changed, loadCounter: loadCounter }

            break;
         

        case "SET_SELECTED_LANGUAGES":
      
            var changed = true
            var loadCounter = state.loadCounter+1
            updateData = {...updateData,reload:true}
            newState = { ...state, selectedLanguages: action.selectedValues, loading: changed, loadCounter: loadCounter }

            break;
        case "SET_TIMEZONE_INPUT_VALUE":
            var timezoneInputState = "empty"
            if(action.value!=""){
                timezoneInputState = "text"
            }   

            if(timezoneInputState!=state.timezoneInputState){
                var timezones = []

                if(timezoneInputState == "empty"){
                    timezones = state.values.globalTimezones 
                }
                else{
                    timezones = state.values.allTimezones
                }

              
                newState = { ...state,timezones:timezones, timezoneInputState: action.selectedTimezones }
            }

            break;

        case "SET_SELECTED_TIMEZONES":

            var changed = true
            var loadCounter = state.loadCounter+1
            updateData = {...updateData,reload:true}

            newState = { ...state, selectedTimezones: action.selectedTimezones, loading: changed, loadCounter: loadCounter }
            /*
            var selectedTimezones    = action.selectedTimezones
            var oldSelectedTimezones = state.selectedTimezones

            if(selectedTimezones.length==0){
                selectedTimezones = [globalTimezones[0]]
            }
            else {
                if (selectedTimezones.length > 1) {
                    var arrayLength = selectedTimezones.length;
                    for (var i = 0; i < arrayLength; i++) {
                        if (selectedTimezones[i].id === "anywhere") {
                            if(oldSelectedTimezones.some(timezone=>{
                                if(timezone.id === "anywhere"){
                                    return true
                                }
                            })){
                                selectedTimezones.splice(i, 1);
                            }
                            else{
                                selectedTimezones = [globalTimezones[0]]
                            }
                            break;
                        }
                    }
                }
            }
            var changed = false
            var loadCounter = state.loadCounter 
            if(selectedTimezones.length!=oldSelectedTimezones.length){
                changed = true
                loadCounter = loadCounter+1
            }
            else{
                if(selectedTimezones.length>=1){
                    if(selectedTimezones[0].id!=oldSelectedTimezones[0].id){
                        changed = true
                        loadCounter = loadCounter+1
                    }
                }
            }
            updateData = {changed:changed}
            newState = { ...state, selectedTimezones: selectedTimezones, loading: changed, loadCounter: loadCounter }
            */
            break;

      

        case "SET_ALL_WEBSITES_CHECKED":


            var websites = [...state.websites]
            websites.forEach(website => {
                if(website.title!=="Rejoco"){
                    website.checked = action.checked
                }
            })
            updateData = {...updateData,reload:true}
            newState = { ...state, websites: websites,loading:true, loadCounter: state.loadCounter+1   }
            break;
        case "SET_WEBSITE_CHECKED":
            var websites = [...state.websites]
            websites.forEach(website=>{
                if(website.title===action.website){
                    website.checked = action.checked
                }
            })
            updateData = {...updateData,reload:true}
            newState = { ...state, websites: websites,loading:true, loadCounter: state.loadCounter+1   }
            break;
        case "SET_SORT_MODE":


           

           
            var sortSavedMode = state.sortSavedMode
            var sortMode      = state.sortMode

            var loading = state.loading
            var loadCounter  = state.loadCounter

            if(action.listContent=="saved"){
                sortSavedMode = action.sortMode
            }
            else{
                sortMode = action.sortMode
                updateData = { ...updateData, reload: true }
                loading = true
                loadCounter  = loadCounter + 1
            }



            newState = { ...state,sortSavedMode:sortSavedMode, sortMode: sortMode, loading: loading, loadCounter:loadCounter  }
            break;

        case "SHOW_MORE_COMPANIES":

            var num = state.visibleCompaniesNum + 60
            var visibleCompanyList = []
            if (state.companySearchList.length > 0) {
                visibleCompanyList = state.companySearchList

            }
            else {
                visibleCompanyList = state.companyList
            }

            if (num > visibleCompanyList.length) {
                num = visibleCompanyList.length
            }
      
            newState = { ...state, visibleCompaniesNum: num }
            break;



        case "SHOW_MORE_JOBS":

            var num = state.visibleJobsNum + 30
            if (num > state.jobList.length) {
                num = state.jobList.length
            }
            newState =  { ...state, visibleJobsNum: num }
            break;
        case "SHOW_JOB_PAGE":

            newState =  {...state,jobPageItem:action.job,searchYScroll:action.searchYScroll}
            break;
        case "SHOW_COMPANY_PAGE":
  
            var company = state.companies[action.companyID]
           // console.log(company)
           // console.log(action.companySlug)
           // alert(action.companySlug)
            newState =  {...state,companyPageItem:company}
            break;
        case "NEW_DATA":

            if (action.data.token) { 
                var popupOpen = ""
                if(action.data.token=="register_error_waitlist"){
                    popupOpen = "waitlist"
                }

                newState =  {...state,popupOpen:popupOpen,user:action.data.user,token:action.data.token,loggingIn:false}
            }
            else{
        
                if (action.loadCounter == state.loadCounter && action.data) {


                    var jobList = state.jobList
                    var similarJobs = state.similarJobs

                    var jobPageItem = state.jobPageItem
                    var companyPageItem = state.companyPageItem
                    var jobs = state.jobs
                    var newJobList = []
                    var completelyLoadedJobCompanies = state.completelyLoadedJobCompanies
                    var completelyLoadedCompanies = state.completelyLoadedCompanies
                    var updateJobList = false

                    var companies = state.companies
                    var companyList = state.companyList

                    var companySearchList = state.companySearchList
                


                    if (action.data.jobList != null) {
                        newJobList = action.data.jobList

                        if (!action.data.doNotUpdateJobList) {
                            jobList = []
                        }
                    }



                    var newCompanyList = action.data.companyList


                    if (newCompanyList) {
                        companyList = newCompanyList
                        if (companyList && companyList.length > 0) {

                            for (var i = 0; i < companyList.length; i++) {
                                var newCompany = companyList[i]
                                companies[newCompany.slug] = newCompany
                            }

                        }
                    }


                    var newCompanySearchList = action.data.companySearchList
                    if(newCompanySearchList){
                        companySearchList = newCompanySearchList
                
                    }


            

                    if (action.data.jobID) {
                        if (action.data.job) {

                            jobPageItem = action.data.job
                            jobPageItem.companyCompletelyLoaded = true
                            completelyLoadedJobCompanies[jobPageItem.slug] = jobPageItem

                            //jobList.push(action.data.job)
                            newJobList.push(jobPageItem)


                            jobs[action.data.job.slug] = jobPageItem


                            updateJobList = true
                        }

                    }

                    if (action.data.similarJobs) {
                        similarJobs = action.data.similarJobs
                        newJobList = newJobList.concat(similarJobs)
                    }

                    if (action.data.companyID) {
                        if (action.data.company) {

                            companyPageItem = action.data.company

                            companyPageItem.completelyLoaded = true
                            completelyLoadedCompanies[companyPageItem.slug] = companyPageItem

                            companies[action.data.company.slug] = companyPageItem





                        }

                    }

                    if (action.data.companies) {
                        var newCompanies = action.data.companies
                        companies = { ...companies, ...newCompanies }
                    }

                    if (newJobList.length > 0) {


                        for (var i = 0; i < newJobList.length; i++) {
                            var newJob = newJobList[i]
                            if(newJob){
                                newJob.companyInfo = companies[newJob.companySlug]
                                jobs[newJob.slug] = newJob
                                
                                if (typeof newJob.values.datePosted === 'string' || newJob.values.datePosted instanceof String){
                                    //newJob.values.datePosted = new Date(newJob.values.datePosted)
                                }
                            
                            
                                if (action.data.companyJobsLoaded) {
                                    newJob.companyCompletelyLoaded = true
                                    completelyLoadedJobCompanies[newJob.slug] = newJob
                                }
                            }
                        }
                        if (!action.data.doNotUpdateJobList) {
                            jobList = jobList.concat(newJobList)
                        }
                        else {
                            updateJobList = true
                        }
                    }

                    for (const [companiesID, company] of Object.entries(companies)) {
                        if (!company.jobsLoaded) {
                            company.jobsLoaded = true
                            company.jobList = []
                            if (company.jobs) {
                                for (var i = 0; i < company.jobs.length; i++) {
                                    var jobFound = jobs[company.jobs[i]]
                                    if (jobFound) {
                                        company.jobList.push(jobFound)
                                    }
                                    else {
                                        company.jobsLoaded = false
                                    }
                                }
                            }
                        }
                    }


                    if (updateJobList) {
                        for (var i = 0; i < jobList.length; i++) {
                            var updateJob = jobList[i]
                            if(updateJob){
                                jobList[i] = jobs[updateJob.slug]
                            }
                        }
                    }


                    newState = { ...state, companySearchList:companySearchList,similarJobs: similarJobs, completelyLoadedJobCompanies: completelyLoadedJobCompanies, completelyLoadedCompanies: completelyLoadedCompanies, companyPageItem: companyPageItem, jobPageItem: jobPageItem, itemsNeedReload: false, jobs: jobs, jobList: jobList, companies: companies, companyList: companyList, visibleJobsNum: 30, visibleCompaniesNum: 60, loading: false, jobsLoaded: true }

                }
                else {
                    newState = { ...state }
                }
                if (action.data.props) {

                    if (action.data.props.categories) {
                        newState.values.categories = action.data.props.categories
                        newState.categories = newState.values.categories
                    }

                    if (action.data.props.globalTimezones) {
                        newState.values.globalTimezones = action.data.props.globalTimezones
                    }

                    if (action.data.props.allTimezones) {
                        newState.values.allTimezones = action.data.props.allTimezones
                    }

                    if (state.timezoneInputState == "empty") {
                        newState.timezones = newState.values.globalTimezones
                    }
                    else {
                        newState.timezones = newState.values.allTimezones
                    }


                    if (action.data.props.languages){
                        newState.values.languages = action.data.props.languages

                        newState.languages = newState.values.languages
                    }
                
                    if(action.data.props.websites){
                        newState.values.websites = action.data.props.websites
                        newState.websites = newState.values.websites
                    }
            
                }
            }
            break;
         
        case "NEW_DATA_ERROR":
            console.log("NEW_DATA_ERROR")
            if(action.loadCounter == state.loadCounter){
                newState =  {...state,visibleJobsNum:0,visibleCompaniesNum:0,loading:false}
            }
            else{
                newState =  {...state}
            }
            break;

    }


    newState = updateStateData(state,newState,updateStateDataAction,updateData)

    return newState
}

export default jobStore
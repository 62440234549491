import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';




import CompanySmall from "./CompanySmall";

import SearchIcon from '@material-ui/icons/Search';
//export default function 
import { NavLink, withRouter } from "react-router-dom";
import NothingFound from "./NothingFound";

import ColoredProgress from "./ColoredProgress";


var rootElement = null
var scrollStateTimeout = null
var checkScrollState = false


function startCheckScrollState(dispatch) {
  checkScrollState = true
  window.onscroll = function (ev) {
    if (checkScrollState) {
      if (!rootElement) {
        rootElement = document.getElementById("root")
      }

      if ((window.innerHeight + window.scrollY) >= rootElement.offsetHeight - window.innerHeight * 0.2 - 10) {
        if (!scrollStateTimeout) {

          dispatch(({ type: "SHOW_MORE_COMPANIES" }))
          scrollStateTimeout = setTimeout(() => {
            clearTimeout(scrollStateTimeout)
            scrollStateTimeout = null
          }, 100)
        }
      }
    }
  };
}

function stopCheckScrollState(dispatch) {
  window.onscroll = () => { }
  checkScrollState = false
};



const CompanyList = (props) => {
  const { history, notEmtpy,...other } = props;
  const dispatch = useDispatch();

  let companyList = useSelector(state => state.companyList);
  let companySearchList = useSelector(state => state.companySearchList);
  let visibleCompaniesNum = useSelector(state => state.visibleCompaniesNum);
  let loading = useSelector(state => state.loading);
  let companySearchValueText = useSelector(state => state.companySearchValueText);
  

  var companyListItems = []

  var visibleCompanyList = []

  if (companySearchList.length > 0) {
    visibleCompanyList = companySearchList

  }
  else {
    visibleCompanyList = companyList
  }


  var num = visibleCompanyList.length


  if (num > visibleCompaniesNum) {
    num = visibleCompaniesNum
  }

  useLayoutEffect(() => {
    startCheckScrollState(dispatch)
    return () => {
      stopCheckScrollState(dispatch)
    };

  }, [])





  const handleCompanyDetailsClick = (company) => {
    if (company) {
      setTimeout(() => {
        var openCompanyURL = "/company/" + company.slug  //companySlug
        dispatch({ type: "SHOW_COMPANY_PAGE", companySlug: company.slug, companyID: company._id })

        history.push(openCompanyURL);

        document.title = "Rejoco - " + company.company

        window.scroll(0, 0);
      }, 10)
    }


  }






  const createCompanyItem = (company) => {
    return <CompanySmall key={"companyitem_" + company.slug} company={company} companyClick={handleCompanyDetailsClick} />
  }


  for (var i = 0; i < num; i++) {
    if(!visibleCompanyList[i].empty){
      companyListItems.push(createCompanyItem(visibleCompanyList[i]))
    }
  }



  /*
  if(num<6){
    var missingNum = 6-num
    for (var i = 0; i < missingNum; i++) {
      companyListItems.push( <div className="companyplaceholeritem" ></div>)
    }
  }
  */
  var noResultsItem = null
  if(companyListItems.length==0 && !loading){
    if(!notEmtpy || companySearchValueText!=""){
      noResultsItem = <NothingFound text={companySearchValueText}/>
    }
   
  }



  let loadingItem = null
  if (loading) {
    loadingItem = <ColoredProgress />
  }

  return (
    <div>
      <div style={{ "height": "3px", "marginLeft": "13px", "marginRight": "13px", "marginTop": "-3px", "marginBottom": "-7px", "overflow": "hidden", "borderBottomLeftRadius": "5px", "borderBottomRightRadius": "5px" }} >
        {loadingItem}
      </div>
      <div className="companyitemcontainer" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: "6px" }}>
        {companyListItems}
        
      </div>
      {noResultsItem}
    </div>
  )
}



export default withRouter(CompanyList);


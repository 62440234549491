import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import { Facebook, Github, Twitter, Google } from "mdi-material-ui";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }



var providerColors = {
  github: {
    primary: '#24292e'
  },
  facebook: {
    primary: '#3b5998'
  },
  twitter: {
    primary: '#1da1f2'
  },
  google: {
    primary: '#4285F4'
  }
};

/*
Properties
base color
icon
button label
 */

export var ProviderButton = function (_Component) {
  _inherits(ProviderButton, _Component);

  function ProviderButton() {
    var _temp, _this, _ret;

    _classCallCheck(this, ProviderButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.handleClick = function (event) {
      if (_this.props.onClick) {
        _this.props.onClick(_this.props.provider);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  ProviderButton.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        providerIcon = _props.providerIcon,
        providerLabel = _props.providerLabel,
        login = _props.login,
        register = _props.register,
        provider = _props.provider,
        restOfProps = _objectWithoutProperties(_props, ['classes', 'providerIcon', 'providerLabel', 'login', 'register', 'provider']);

    var label = void 0;
    if (login) {
      label = 'Log in with ' + providerLabel;
    } else if (register) {
      label = 'Register with ' + providerLabel;
    } else {
      label = providerLabel;
    }

    return React.createElement(
      Button,
      _extends({}, restOfProps, { onClick: this.handleClick, classes: {
          root: classes.root
        }
      }),
      React.createElement(providerIcon, { className: classes.leftIcon }),
      label
    );
  };

  return ProviderButton;
}(Component);

function createProviderButton(provider, baseColor, icon, label) {
  var _class2, _temp2;

  var styles = function styles(theme) {
    return {
      leftIcon: {
        marginRight: theme.spacing.unit
      },
      root: {
        backgroundColor: baseColor,
        '&:hover': {
          backgroundColor: darken(baseColor, 0.2)
        }
      }
    };
  };

  var inner = withStyles(styles)(ProviderButton);

  var component = (_temp2 = _class2 = function (_Component2) {
    _inherits(component, _Component2);

    function component() {
      _classCallCheck(this, component);

      return _possibleConstructorReturn(this, _Component2.apply(this, arguments));
    }

    component.prototype.render = function render() {
      return React.createElement(inner, _extends({
        providerIcon: icon,
        providerLabel: label,
        provider: provider
      }, this.props));
    };

    return component;
  }(Component), _class2.propTypes = _extends({}, Button.propTypes, {
    login: PropTypes.bool,
    register: PropTypes.bool,
    onClick: PropTypes.func
  }), _temp2);

  return component;
}

export var FacebookButton = createProviderButton("facebook", providerColors.facebook.primary, Facebook, "Facebook");
export var GithubButton = createProviderButton("github", providerColors.github.primary, Github, "Github");
export var TwitterButton = createProviderButton("twitter", providerColors.twitter.primary, Twitter, "Twitter");
export var GoogleButton = createProviderButton("google", providerColors.google.primary, Google, "Google");
import Cookies from 'universal-cookie';


export  let apiURL = ""// "http://localhost/rejoco"//http://192.168.1.42:3456"//"http://localhost:3456"////
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    apiURL = "http://localhost/rejoco"
} else {
    apiURL = ""
}

export function fetchData(dispatch,state,newState, action) {
    console.log("fetchData ("+newState.loadCounter+")")
    var loadCounter = newState.loadCounter
    var savedJobs = []
    if(action.initialize){

    }


    var userLang = navigator.language || navigator.userLanguage; 

    fetch(apiURL + '/data.php' , {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ 
            searchValue:newState.searchValue,
            websites:newState.websites,
            sortMode:newState.sortMode,
            rating:newState.selectedRating,
            regions:newState.selectedTimezones,
            languages:newState.selectedLanguages,
            categories:newState.selectedCategories,
            date:newState.selectedDate,
            dateRange:newState.selectedDateRange,
            dateValue:newState.selectedDateValue,
            salary:newState.selectedSalaryValues,
            salaryRange:newState.selectedSalaryRange,
            salaryDefault:newState.values.salaryValues,
            companySize:newState.selectedCompanySize,
            initialize:action.initialize,
            jobID:action.jobID,
            companyID:action.companyID,
            companySearchValue:action.companySearchValue,
            startCompanySearch:action.startCompanySearch,
            path:action.path,
            login:action.login,
            register:action.register
          
        })
    }).then(function (response) {
        return response.json();
    })
        .then(function (data) {
          
            dispatch(({ type: "NEW_DATA", data: data,dispatch:dispatch,loadCounter:loadCounter}))

        }).catch(function(error) {
            dispatch(({ type: "NEW_DATA_ERROR",loadCounter:loadCounter}))
            
        });
}
  
import React from 'react';
import { useEffect,useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'



import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import EuroIcon from '@material-ui/icons/Euro';
import StarIcon from '@material-ui/icons/Star';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CodeIcon from '@material-ui/icons/Code';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EventIcon from '@material-ui/icons/Event';
import EasyApplyIcon from '@material-ui/icons/FlashOn';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {

   
  },

  toogleIcon:{
    width:"30.3px"
  },


 
}));




export default function JobSort(props) {  
  const { mode, ...other } = props;
    const classes = useStyles();
  const dispatch = useDispatch();
 
  let disableSort = false

  let sortBarValue              = useSelector(state => state.widthValues.sortBar?state.widthValues.sortBar.value:0)


  const sortValueJobs  = useSelector(state => state.sortMode); //React.useState('Custom Sort');
  const sortValueSaved = useSelector(state => state.sortSavedMode); //React.useState('Custom Sort');

  const numJobs = useSelector(state => state.jobList.length)

  const numSavedJobs = useSelector(state => state.savedJobs.length)

  


  const loading = useSelector(state => state.loading)

  const handleSortChange = (event, newSortValue) => {

  
    if(newSortValue){
      dispatch(({ type: "SET_SORT_MODE", sortMode: newSortValue,listContent:mode}))
    }

    
  };

  var sortValue = 0
  if(mode==="saved"){
    sortValue = sortValueSaved 
  }
  else{
    sortValue = sortValueJobs
  }



  var jobNumInfo =  null
  
  
  var sortByText = null
  var containerStyle = {verticalAlign:"middle" }
  if(sortBarValue==1){
    
    sortByText = <div  style={{"float":"left","marginRight":"15px","marginLeft":"5px","height":"35px","lineHeight":"35px","fontSize":"17px"}}> Sort by:</div>

    if(!loading){

    


      if(mode==="saved"){

        var numJobsInfo = numSavedJobs

      
        if(numSavedJobs>=100){
          numJobsInfo = "1000+"
        }

        if(numJobsInfo>0){
          jobNumInfo =   <div  style={{float: "right",marginLeft: "5px","height":"35px","lineHeight":"35px","fontSize":"17px"}}><span style={{color:"green",fontWeight: "normal"}}> {numJobsInfo}</span> Job Openings</div>
        }
      }
      else{

        var numJobsInfo = numJobs

      
        if(numJobs>=100){
          numJobsInfo = "1000+"
        }
        if(numJobsInfo>0){
          jobNumInfo =   <div  style={{float: "right",marginLeft: "5px","height":"35px","lineHeight":"35px","fontSize":"17px"}}><span style={{color:"green",fontWeight: "normal"}}> {numJobsInfo}</span> Job Openings</div>
        }
        
      }
     
    }


  }
  else{
    containerStyle = {"display":"flex","alignItems":"center","justifyContent":"center",width:"100%",verticalAlign:"middle"}
  }



  let toggleChildren = [
    <ToggleButton key={1} title="Relevance" value="Relevance" disabled={disableSort}>
    <FormatListBulletedIcon className={classes.toogleIcon} fontSize="small" />
  </ToggleButton>,
    <ToggleButton key={2} title="Date (Job Posting)" value="Date" disabled={disableSort}>
      <EventIcon className={classes.toogleIcon} fontSize="small" />
    </ToggleButton>,
    <ToggleButton key={3} title="Rating" value="Rating" disabled={disableSort}>
      <StarIcon className={classes.toogleIcon} fontSize="small" />
    </ToggleButton>,
    
    <ToggleButton key={4} title="Salary" value="Salary" disabled={disableSort||true}>
      <EuroIcon className={classes.toogleIcon} fontSize="small" />
    </ToggleButton>,
    
    <ToggleButton  key={6} title="Tags" value="Tech Stack" disabled={disableSort||true}>
      <CodeIcon className={classes.toogleIcon} fontSize="small" />
    </ToggleButton>,
    <ToggleButton  key={5} title="Location" value="Location" disabled={disableSort||true}>
      <LocationOnIcon className={classes.toogleIcon} fontSize="small" />
    </ToggleButton >,

    ,
  ]

  if(mode==="saved"){
    toggleChildren.splice(1, 0,
      <ToggleButton key={7} title="Date (Saved)" value="DateAdded" disabled={disableSort}>
        <PlaylistAddIcon className={classes.toogleIcon} fontSize="small" />
      </ToggleButton>,

    );
  }



    var findItem = 
        <Paper className="jobsort" elevation={1} style={{overflow:"hidden",marginBottom:"1px",padding:"10px",paddingBottom:"5px"}}>
         <div style={containerStyle}>
            {sortByText}
         
            <ToggleButtonGroup size="small" style={{float: "left"}} value={sortValue} onChange={handleSortChange} exclusive  className={classes.toggleSort} >
                {toggleChildren}
              </ToggleButtonGroup>
            
              {jobNumInfo}
           </div>
           
             
      
        </Paper>


    function fetchData() {

    }
    let hasMore = true;

    return (

        <>



        
           {findItem}


     

    </>

  );
}

import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";


import Item from "../components/Item"; 



const SimilarJobs = (props) => {
    const { job, history, location, ...other } = props;
    const dispatch = useDispatch();

    var similarJobsNum = useSelector(state => state.similarJobs.length)
    var similarJobs = useSelector(state => state.similarJobs)

    if(similarJobsNum>0){

        var similarJobsItems = similarJobs.map(sjob=>{
            if(sjob){
                return (
                    <Item job={sjob} showRating={true} showCompany={true} />
                    )
            }
            else{
                return null
            }
           
        })

        return (
            <>
                <div style={{ marginLeft: "15px", marginTop: "50px", marginBottom: "30px" }}><h3>Similar Jobs</h3></div>
                {similarJobsItems}
            </>
        )
    } 
    else{
        return null
    }

    
}




export default withRouter(SimilarJobs);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoginRegisterPage from "./LoginRegisterPage";
//export default function 
import { NavLink, withRouter } from "react-router-dom";


const AccountPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    let user              = useSelector(state => state.user)


    var loginRegister = null
    var accountPage = null

    if(!user){
        loginRegister = <LoginRegisterPage/>
    }
    else{
        accountPage = null
    }
    


    return (
        <div class="inner-container" >
           {loginRegister}
           {accountPage}
            <div style={{ marginBottom: "110px", marginTop: "0px" }} />
        </div>
    )
}




export default AccountPage;
import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionBox: {
        marginLeft: "-13px",
        marginRight: "-13px",
        paddingBottom: "1px"
    },
    accordionBoxLast: {
        marginLeft: "-13px",
        marginRight: "-13px",

    },


    accordion: {
        marginLeft: "-13px!important",
        marginRight: "-13px!important",
        paddingBottom: "1px",
        borderTop: "1px solid #EEEEEE"

    },
    activeFilters: {
        minHeight: "34px",
        paddingLeft: "0px",
        paddingTop: "10px",
        paddingBottom: "5px",
        fontSize: theme.typography.pxToRem(14),
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },

    activeFiltersNone: {

        minHeight: "34px",
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "5px",
        fontSize: theme.typography.pxToRem(14),
    }

}));






export default function ActiveFilters(props) {
    const { accordionClassName, showInDialog, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    var accordionClass = classes.accordion
    if (accordionClassName) {
        accordionClass = accordionClassName
    }


    
    var selectedCategories = useSelector(state => state.selectedCategories)
    var selectedCategoriesNum = useSelector(state => state.selectedCategories.length)

    var selectedTimezones = useSelector(state => state.selectedTimezones)
    var selectedTimezonesNum = useSelector(state => state.selectedTimezones.length)

    var selectedLanguages = useSelector(state => state.selectedLanguages)
    var selectedLanguagesNum = useSelector(state => state.selectedLanguages.length)

    var selectedRating = useSelector(state => state.selectedRating)
    var selectedRatingText = useSelector(state => state.selectedRatingText)

    var selectedDate = useSelector(state => state.selectedDate)
    var selectedDateText = useSelector(state => state.selectedDateText)

    var salaryValues = useSelector(state => state.selectedSalaryValues)
    var salaryValuesDefault = useSelector(state => state.values.salaryValues)
    var selectedSalaryRange = useSelector(state => state.selectedSalaryRange)

    var selectedCompanySize = useSelector(state => state.selectedCompanySize)
    var selectedCompanySizeTextValues = useSelector(state => state.values.selectedCompanySizeTextValues)

    var websites = useSelector(state => state.websites)
    var websitesNum = useSelector(state => state.websites.length)



    const handleDelete = (type,id) => () => {
        //alert("REMOVE_ACTIVE_FILTER filterType: "+type+" id: "+id)
        dispatch(({ type: "REMOVE_ACTIVE_FILTER", filterType: type,id:id }))
    };

    const handleClick = (type,id) => () => {
        console.info('You clicked the Chip.');
    };

    const handleClearAll = () => {
    
        dispatch(({ type: "REMOVE_ALL_ACTIVE_FILTERS" }))
    };

    

    const createChip = (chips, item, type) => {
        chips.push(
            <Chip
                style={{ marginRight: "5px", marginRight: "5px", marginBottom: "10px" }}
                variant="outlined"
                size="small"
                label={item.title}
                onClick={handleClick(type, item.id)}
                onDelete={handleDelete(type, item.id)}
            />)
    }


    const createArrayChips = (chips, array, type) => {
        array.forEach(item => {
            createChip(chips, item, type)
        })
    }



    var activeFiltersClass = classes.activeFilters
    var activeFilters = []
    var clearAllButton = null


    
    if(selectedCategoriesNum>0){
        createArrayChips(activeFilters, selectedCategories, "selectedCategories")
    }

    if(selectedTimezonesNum>0){
        createArrayChips(activeFilters, selectedTimezones, "selectedTimezones")
    }
    if(selectedLanguagesNum>0){
        createArrayChips(activeFilters, selectedLanguages, "selectedLanguages")
    }
    if(selectedDate){

        createChip(activeFilters, {id:"date",title:selectedDateText}, "selectedDate")

    }
    if(selectedRating!='0'){

        createChip(activeFilters, {id:"rating",title:selectedRatingText}, "selectedRating")

    }
  
    if( (salaryValues[0] != salaryValuesDefault[0] && salaryValues[0] != salaryValuesDefault[1]) || 
        (salaryValues[1] != salaryValuesDefault[0] && salaryValues[1] != salaryValuesDefault[1])){

        var selectedSalaryRangeText = ""
        if(selectedSalaryRange=="year"){
            selectedSalaryRangeText= "per year"
        }
        else{
            selectedSalaryRangeText= "per month"
        }


        if(salaryValues[0] == salaryValuesDefault[0] || salaryValues[0] == salaryValuesDefault[1]){
            createChip(activeFilters, {id:"salary",title:salaryValues[1]+" € "+selectedSalaryRangeText},"selectedSalary" )
        }
        else if(salaryValues[1] == salaryValuesDefault[1] || salaryValues[1] == salaryValuesDefault[0]){
            createChip(activeFilters, {id:"salary",title:salaryValues[0]+" € "+selectedSalaryRangeText},"selectedSalary" )
        }
        else{
            if(salaryValues[1] > salaryValues[0]){
                createChip(activeFilters, {id:"salary",title:salaryValues[0]+" € - "+salaryValues[1]+" € "+selectedSalaryRangeText},"selectedSalary" )
            }
            else{
                createChip(activeFilters, {id:"salary",title:salaryValues[1]+" € - "+salaryValues[0]+" € "+selectedSalaryRangeText},"selectedSalary" )
            }

           
        }
       
    }


    if(selectedCompanySize !='0'){
        createChip(activeFilters, {id:"companysize",title:selectedCompanySizeTextValues[selectedCompanySize]}, "selectedCompanySize")
    }

    

    var allSelectedState = true
    websites.some((website) => {
        if (website.jobSource) {
            if (website.title !== "Rejoco") {
                if (!website.checked) {
                    allSelectedState = false
                    return true
                }
            }
        }
    })

    if(!allSelectedState){
        createChip(activeFilters, {id:"website",title:"Filter Websites"}, "selectedWebsites")
    }


    

    if(activeFilters.length==0){

        activeFilters = "None"
        activeFiltersClass = classes.activeFiltersNone

    }
    else{
       // if(activeFilters.length>1){
            var clearAllButtonStyle = null
            if(showInDialog){
                clearAllButtonStyle = {float:"left","marginTop":"-3px","marginLeft":"2px"}
            }
            else{
                clearAllButtonStyle = {float:"right","marginTop":"-3px","marginRight":"2px"}
            }
    
    
            clearAllButton = <div style={clearAllButtonStyle}>
            <IconButton aria-label="clear" size = "small" onClick={handleClearAll} title="Clear All">
                <ClearIcon fontSize="small" />
            </IconButton>
            </div>
       // }
    
    }

    return (<>

        <div style={{float:"left",marginLeft:"3px"}}><Typography className={classes.heading}>Active Filters</Typography></div>
        {clearAllButton}
        <div className={activeFiltersClass} style={{"content":"\"\"","clear":"both","display":"block"}}>
            {activeFilters}
        </div>

    </>

    );
}


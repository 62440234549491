import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';


import { useSelector, useDispatch } from 'react-redux'




import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Rating from '@material-ui/lab/Rating';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

import StarIcon from '@material-ui/icons/Star';

import IconText from "./IconText"

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionBox: {
        marginLeft: "-13px",
        marginRight: "-13px",
        paddingBottom: "1px"
    },
    accordionBoxLast: {
        marginLeft: "-13px",
        marginRight: "-13px",

    },


    accordion: {
        marginLeft: "-13px!important",
        marginRight: "-13px!important",
        paddingBottom: "1px",
        borderTop: "1px solid #EEEEEE"

    },
    activeFilters: {
        minHeight: "20px",
        paddingLeft: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: theme.typography.pxToRem(14),
    }

}));



export default function FilterRating(props) {
    const { accordionClassName, showInDialog, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    var accordionClass = classes.accordion
    if (accordionClassName) {
        accordionClass = accordionClassName
    }


    var selectedRating = useSelector(state => state.selectedRating)


    const handleChange = (event, newValue) => {
        if(newValue){
            dispatch(({ type: "SET_SELECTED_RATING", selectedValue: newValue }))
        }
    };

    return (
        <Accordion className={accordionClass} TransitionProps={{ unmountOnExit: true }} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >

                <Typography className={classes.heading}>{<IconText text={"Rating"} icon={<StarIcon fontSize="small" />}/>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{"display":"flex","alignItems":"center","justifyContent":"center",width:"100%"}}>
                   
                    <ToggleButtonGroup orientation="vertical" value={selectedRating} exclusive onChange={handleChange}>
                        <ToggleButton value="0" aria-label="quilt" style={{ minWidth: "184px" }}>
                            <span style={{ textTransform: "none" ,color:"black"}}>All Ratings</span>
                        </ToggleButton>
                        <ToggleButton value="4" aria-label="list" style={{ minWidth: "184px" }}>
                            <Rating size="small" name="read-only" value={4} readOnly /><span style={{ marginLeft: "10px", textTransform: "none" }}>&amp; Up</span>
                        </ToggleButton>
                        <ToggleButton value="3" aria-label="module" style={{ minWidth: "184px" }}>
                            <Rating size="small" name="read-only" value={3} readOnly /> <span style={{ marginLeft: "10px", textTransform: "none" }}>&amp; Up</span>
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="quilt" style={{ minWidth: "184px" }}>
                            <Rating size="small" name="read-only" value={2} readOnly /> <span style={{ marginLeft: "10px", textTransform: "none" }}>&amp; Up</span>
                        </ToggleButton>
                        <ToggleButton value="1" aria-label="quilt" style={{ minWidth: "184px" }}>
                            <Rating size="small" name="read-only" value={1} readOnly />  <span style={{ marginLeft: "10px", textTransform: "none" }}>&amp; Up</span>
                        </ToggleButton>

                    </ToggleButtonGroup>
                   


                </div>

            </AccordionDetails>
        </Accordion>


    );
}


import React from 'react';

import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SearchIcon from '@material-ui/icons/Search';
import ForumIcon from '@material-ui/icons/Forum';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Typography from '@material-ui/core/Typography';

import BookmarkIcon from '@material-ui/icons/Bookmark';

import WorkIcon from '@material-ui/icons/Work';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles, withStyles } from '@material-ui/core/styles';

export const tabItemValues = [
    {
        pathValues: ["","jobs","job"],
        label: "Jobs",
        icon: <SearchIcon />,
        path: "/",
        title: "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting",

    }, 
    
    
    
    {
        pathValues: ["saved"],
        label: "Saved",
        icon: <BookmarkIcon />,
        path: "/saved",
        title: "Rejoco - Saved - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    },
    {
        pathValues: ["company","companies"],
        label: "Companies",
        icon: <HomeWorkIcon />,
        path: "/companies",
        title: "Rejoco - Companies - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    },
    
    {
        pathValues: ["postjob"],
        label: "Post a job",
        icon: <WorkIcon />,
        path: "/postjob",
        title: "Rejoco - Post a job - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    },
    
    
    {
        pathValues: ["account"],
        label: "My Account",
        icon: <PersonPinIcon />,
        path: "/account",
        title: "Rejoco - My Account - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    }

]

let tabItemValueMap = {}
tabItemValues.forEach((tabItem,index)=>{
    tabItem.index = index
    if(tabItem.pathValues){
        tabItem.pathValues.forEach((pathID)=>{
            tabItemValueMap[pathID] = tabItem
        })
    }
})

export const getTabItemValueMap = () => {
    return tabItemValueMap
}




export function getTabIndex(pathname) {
    var pathArray = pathname.split('/');
    var newTabIndex = tabItemValues.length+1
    var itemType = ""
    if (pathArray.length >= 2) {
        itemType = pathArray[1].toLowerCase()
        switch (itemType) {
            case "":
                break;
            case "job":
                break;
            case "company":
                break;
            case "companies":
                break;
            case "postjob":
                break;
            case "account":
                break;
        }

        var newTab = tabItemValueMap[itemType]
        if(newTab){
            newTabIndex = newTab.index
        }

    }
    else {
        var newTab = tabItemValueMap[""]
        if(newTab){
            newTabIndex = newTab.index
        }
    }
    // alert(newTabIndex+"   pathArray.length: "+pathArray.length+"    itemType: "+itemType)
    return newTabIndex
}






export const StyledTabSize = (height) =>  withStyles((theme) => ({
    root: {
  
  
      textTransform: 'none',
      minWidth: height,
      minHeight: height-1,
      fontSize: theme.typography.pxToRem(13.5),
      fontWeight: theme.typography.fontWeightRegular,//fontWeight: theme.typography.fontWeightRegular,
      marginLeft: theme.spacing(1),
      /*fontFamily: [
       
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')  ,*/
    
      '&:hover': {
        color: '#3A3A35',
        opacity: 1,
      },
      '&$selected': {
        color: 'black',
        fontWeight: theme.typography.fontWeightRegular,
  
  
      },
      '&:focus': {
        color: 'black',
  
  
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);


 export const StyledTab = StyledTabSize(90)

 export const StyledTabSmall = StyledTabSize(35)



export const StyledTabs = withStyles({
    root: {
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',

        '& > span': {
            maxWidth: 89,
            width: '100%',
            height: "6px",
            marginTop: "-5px",
            backgroundColor: '#F7F7F7',
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#FFFFFF",
            boxShadow: "0px 0px 10px 0px #FBDDC1"
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


export const StyledTabsSmall = withStyles({
    root: {
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',

        '& > span': {
            maxWidth: 89,
            width: '100%',
            height: "3px",
            marginTop: "-2px",
            backgroundColor: '#F8B575',
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#FFFFFF",
            boxShadow: "0px 0px 10px 0px #FBDDC1"
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


export const StyledTabVertical = withStyles((theme) => ({
    root: {

        textTransform: 'none',
        minWidth: 90,
        minHeight: 89,
        fontWeight: theme.typography.fontWeightMedium,//fontWeight: theme.typography.fontWeightRegular,
        marginLeft: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#3A3A35',
            opacity: 1,
        },
        '&$selected': {
            color: 'black',
            fontWeight: theme.typography.fontWeightMedium,


        },
        '&:focus': {
            color: 'black',


        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);









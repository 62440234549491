

import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



//export default function 
import { NavLink, withRouter } from "react-router-dom";


const Popups = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    let popupOpen = useSelector(state => state.popupOpen)

    var popupItemLoginRegister = null
    var popupItemWaitlist = null

    const handleClose = () => {
      dispatch({ type: "SET_POPUP_STATE",value:"" })
    };

    const openLoginPage = () =>{
        dispatch({ type: "SET_LOGIN_REGISTER_TAB", value:0 })
        window.scroll(0, 0);
        history.push("/account");
    }

    const openRegisterPage = () =>{
        dispatch({ type: "SET_LOGIN_REGISTER_TAB", value:1 })
        window.scroll(0, 0);
        history.push("/account");
    }


   
    popupItemLoginRegister =  (
      <div>
        <Dialog
          open={popupOpen==="loginRegister"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
         {<DialogTitle id="alert-dialog-title">{"Rejoco - Sign in"}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Sign in to save a list of jobs that interest you
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{openLoginPage();handleClose()}} color="primary">
              Sign In
            </Button>
            <Button onClick={()=>{openRegisterPage();handleClose()}} color="primary" >
              Create Account
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );


    popupItemWaitlist = (
        <div>
          <Dialog
            open={popupOpen==="waitlist"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
           {<DialogTitle id="alert-dialog-title">{"Rejoco - Waitlist"}</DialogTitle>}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Everyone who registers to sign up will be added to a waitlist. You do not need to re-register.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{
                  handleClose()
                  setTimeout(()=>{
                    window.scroll(0, 0);
                    history.push("/");
                  },200)
                  
            }} color="primary" >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );

    return <>
    {popupItemLoginRegister}
    {popupItemWaitlist}
    </>
    
    
    
}




export default withRouter(Popups);
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ProviderChoices from "./ProviderChoices";
import LocalUserLogin from "./LocalUserLogin";
import _ from 'lodash';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }



var styles = function styles(theme) {
  return {
    root: {
      "& > * + *": {
        marginTop: theme.spacing.unit * 2
      }
    },
    or: {
      textAlign: 'center'
    }
  };
};

var Login = function (_Component) {
  _inherits(Login, _Component);

  function Login() {
    _classCallCheck(this, Login);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  Login.prototype.render = function render() {
    var _props = this.props,
        classes = _props.classes,
        onLogin = _props.onLogin,
        onLoginWithProvider = _props.onLoginWithProvider,
        loginFailed = _props.loginFailed,
        providers = _props.providers,
        disableLocal = _props.disableLocal;

    return React.createElement(
      'div',
      { className: classes.root },
      !disableLocal && React.createElement(LocalUserLogin, {
         onLogin: onLogin,
         loginFailed: loginFailed,
         t:_props.t,
        
        }),
      !disableLocal && !_.isEmpty(providers) && React.createElement(
        'div',
        { className: classes.or },
        'or'
      ),
      !_.isEmpty(providers) && React.createElement(ProviderChoices, { login: true,
        onChoice: onLoginWithProvider,
        providers: providers
      })
    );
  };

  return Login;
}(Component);

Login.propTypes = process.env.NODE_ENV !== "production" ? {
  onLogin: PropTypes.func,
  onLoginWithProvider: PropTypes.func,
  providers: PropTypes.arrayOf(PropTypes.string),
  loginFailed: PropTypes.string,
  disableLocal: PropTypes.bool
} : {};


export default withStyles(styles)(Login);
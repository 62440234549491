import logoDefault from '../images/tropical.png'

import * as timeago from 'timeago.js';


import {apiURL} from "../data/LoadItems";


export function getJobPortalName(websites, job) {
    var name = job.portal
    var num = 0
    for (var i = 0; i < websites.length; i++) {
        if (job[websites[i].id]) {
            if (num > 0) {
                name = name + ", " + websites[i].title
            }
            else {
                name = websites[i].title
            }
            num++
        }
    }
    return name
}

export function getCompanyPicture(websites, company, doNotUseDefault) {
    var logo = "";
    for (var i = 0; i < websites.length && !logo; i++) {
        if (!logo) {
            var companyInfoPortal = company[websites[i].id]
            if (companyInfoPortal) {
                if (companyInfoPortal.logo) {
                    logo = companyInfoPortal.logo
                }
                else if (companyInfoPortal.companyLogo) {
                    logo = companyInfoPortal.companyLogo
                }
            }
        }
    }

    if (!logo && !doNotUseDefault) {
        logo = logoDefault
    }

    return logo
}


export function getJobPicture(websites, job) {

    if (job.logo) {
        return job.logo
    }
    else if (job.logoCache) {
        return job.logoCache
    }
    else {

        var logo = ""

        if (job.companyInfo) {
            logo = getCompanyPicture(websites, job.companyInfo, true)
        }

        if (!logo) {
            for (var i = 0; i < websites.length && !logo; i++) {
                var jobInfoPortal = job[websites[i].id]
                if (jobInfoPortal) {
                    if (jobInfoPortal.companyLogo) {
                        logo = jobInfoPortal.companyLogo
                    }
                    else if (jobInfoPortal.logo) {
                        logo = jobInfoPortal.logo
                    }
                }
            }

        }

        if (logo) {
            job.logoCache = logo
            return logo
        }
        else {
            job.logoCache = logoDefault
            return logoDefault
        }
    }
}


export function getCompanyRating(websites, company) {
    var companyValues = {}

    if (!company.rating || !companyValues.ratingCount || !companyValues.ratingNum) {
        companyValues.rating = 0
        companyValues.ratingCount = 0
        companyValues.ratingNum = 0
        for (var i = 0; i < websites.length; i++) {
            var companyPortal = company[websites[i].id]
            if (companyPortal) {
                if (companyPortal.ratingCount > 0) {
                    companyValues.rating = companyValues.rating + companyPortal.rating
                    companyValues.ratingCount = companyValues.ratingCount + companyPortal.ratingCount
                    companyValues.ratingNum++
                }
            }
        }
        if (companyValues.ratingNum > 1) {
            companyValues.rating = companyValues.rating / companyValues.ratingNum
        }
        company.rating = companyValues.rating
        company.ratingCount = companyValues.ratingCount
        company.ratingNum = companyValues.ratingNum
    }
    else {
        companyValues.rating = company.rating
        companyValues.ratingCount = company.ratingCount
        companyValues.ratingNum = company.ratingNum
    }


    return companyValues


}

export function getCompanyURL(websites, company) {
    var companyValues = {}
    companyValues.url = ""

    if (company.type == "company") {
        if (!company.url) {
            companyValues.url = ""
            for (var i = 0; i < websites.length; i++) {
                var companyPortal = company[websites[i].id]
                if (companyPortal) {
                    if (companyPortal.url) {
                        companyValues.url = companyPortal.url
                        break;
                    }
                }
            }
            company.url = companyValues.url
        }
        else {
            companyValues.url = company.url

        }
    }
    else {
        if (!company.companyURL) {
            companyValues.url = ""
            for (var i = 0; i < websites.length; i++) {
                var companyPortal = company[websites[i].id]
                if (companyPortal) {
                    if (companyPortal.companyURL) {
                        companyValues.url = companyPortal.companyURL
                        break;
                    }
                }
            }
            company.companyURL = companyValues.url
        }
        else {
            companyValues.url = company.companyURL

        }
    }
    if (companyValues.url) {
        companyValues.domain = getDomain(companyValues.url)
    }
    else {
        companyValues.domain = "Website"
    }



    return companyValues


}


export function getCompanyValues(websites, company) {

    if (company.values) {

    
        company.values.logoURL = getPictureURL(company.values.logo)

        return company.values
    }
    else {
        var companyValues = getCompanyRating(websites, company)

        companyValues.logo = getCompanyPicture(websites, company)


        companyValues = { ...companyValues, ...getCompanyURL(websites, company) }


        company.values = companyValues

        company.values.logoURL = getPictureURL(company.values.logo)

        return companyValues
    }


}


export function getDomain(url) {
    var domainPart = (new URL(url)).hostname

    var domainParts = domainPart.split(".")

    if (domainParts.length > 2) {
        domainPart = domainParts[1] + "." + domainParts[2]
    }
    return domainPart
}


export function getPictureURL(img){
    var imgURL = img
    if (!img) {
        imgURL = logoDefault
    }
    else{
        var format = img.substring(img.length - 4)
        if(img.indexOf("_rejocoimg")  != -1){
            imgURL = apiURL+"/image.php?id="+img
        }
    }

    return imgURL

}


export function getJobValues(websites, job) {
    if(job){
        if (job.values) {

            if (!job.values.logo) {
                job.values.logo = logoDefault
            }
            job.values.timeago = timeago.format(job.values.datePosted)


        
            job.values.logoURL = getPictureURL(job.values.logo)
          


            return job.values
        }
        else {

            var jobValues = {}

            jobValues.logo = getJobPicture(websites, job)

            if (job.companyInfo) {
                if (!job.rating && !job.values.rating) {
                    var companyRating = getCompanyRating(websites, job.companyInfo)
                    jobValues = { ...companyRating, ...jobValues }
                }
                else {
                    if (job.rating) {
                        jobValues.rating = job.rating
                        jobValues.ratingCount = job.ratingCount
                        jobValues.ratingNum = job.ratingNum
                    }
                    else if (job.values.rating) {
                        jobValues.rating = job.values.rating
                        jobValues.ratingCount = job.values.ratingCount
                        jobValues.ratingNum = job.values.ratingNum
                    }
                }
            }


            jobValues.descInnerHTML = ""

            jobValues.timeago = timeago.format(job.values.datePosted)

            jobValues.desc = ""
            jobValues.portalText = ""
            jobValues.applyURL = ""
            jobValues.applyURLs = []
            jobValues.region = ""
            jobValues.skills = []
            jobValues.easyApply = false

            var applyURLs = {}


            var num = 0

            for (var i = 0; i < websites.length; i++) {
                var jobPortal = job[websites[i].id]
                if (jobPortal) {
                    if(jobPortal.easyApply){
                        jobValues.easyApply = true
                    }


                    if (jobPortal.descriptionText) {
                        if (!jobValues.desc || jobValues.desc.length < jobPortal.descriptionText.length) {
                            jobValues.desc = jobPortal.descriptionText
                            if (jobPortal.description) {
                                jobValues.descInnerHTML = "<div>" + jobPortal.description + "</div>"
                            }
                        }
                    }
                    if (jobPortal.skills && jobPortal.skills.length > 0) {
                        jobValues.skills = jobPortal.skills
                    }

                    if (jobPortal.applyURLs && jobPortal.applyURLs.length > 0) {
                        jobValues.applyURL = jobPortal.applyURLs[0]
                        jobPortal.applyURLs.forEach(applyURL => {
                            if (!applyURLs[applyURL]) {
                                applyURLs[applyURL] = true
                                jobValues.applyURLs.push(applyURL)
                            }
                        })
                    }

                    if (jobPortal.locationText) {
                        if (jobPortal.locationTextFull) {
                            jobValues.region = jobPortal.locationTextFull
                        }
                        else if (jobPortal.locationCityText) {
                            jobValues.region = jobPortal.locationCityText
                        }
                        else {
                            jobValues.region = jobPortal.locationText
                        }

                    }


                    if (num > 0) {
                        jobValues.portalText = jobValues.portalText + ", " + websites[i].title
                    }
                    else {
                        jobValues.portalText = websites[i].title
                    }
                    num++
                }
            }
            job.values = jobValues


            job.values.logoURL = getPictureURL(job.values.logo)

            return jobValues
        }
    }
    else{
        return {}
    }
}





import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";

import ComingSoonPage from './ComingSoonPage'

const DefaultPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();



    return <ComingSoonPage/>
    
    /*
    (
        <div class="inner-container">
            <div style={{ marginBottom: "110px", marginTop: "0px" }} />
        </div>
    )
    */
}




export default DefaultPage;
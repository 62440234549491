import React from 'react';
import { useEffect, useLayoutEffect } from 'react'
import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import InfiniteScroll from 'react-infinite-scroll-component';
import newId from '../utils/newid';
import { useSelector, useDispatch } from 'react-redux'
import ItemFilter from "../components/ItemFilter";

import { getDomain } from "../utils/Utils";

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';
import { getJobPicture, getJobPortalName, getJobValues } from "../utils/Utils";
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import Rating from '@material-ui/lab/Rating';

import BlobImage from './BlobImage';


import { NavLink, withRouter } from "react-router-dom";




const useStyles = makeStyles(theme => ({
    root: {


    },



}));


var defaultRating = 4


function Company(props) {
    const { job, page,showRating,showCompany, history,companyClick, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    let slug = ""
    if(job){
        slug = job.slug
    }


    let loading = useSelector(state => state.loading);
    let websites = useSelector(state => state.websites);
    let jobSelectedCounter = useSelector(state => state.jobLastClicked ? ((slug === state.jobLastClicked.slug) ? state.jobSelectedCounter : 0) : 0);


    const itemRef = React.useRef();
    const detailsRef = React.useRef();
   

    const jobValues = getJobValues(websites, job)


    var openURL = ""
    
    if(job){
        openURL = job.url
        if (typeof job.hideDetailsFast === 'undefined' || job.hideDetailsFast == null) {
            job.hideDetailsFast = true
        }
    }




    if (jobValues.applyURL) {
        openURL = jobValues.applyURL
    }



    var jobID = slug


    var openDetailsURL = "/job/" + jobID


    var openClickTitleURL = null
    if (page) {
        openClickTitleURL = openURL
    }
    else {
        openClickTitleURL = openDetailsURL
    }


    function isTopInViewport(element, offset) {
        if(!offset){
            offset = 0
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= offset
        );
    }

    function isBottomInViewport(element, height) {
        if (!height) {
            height = 0
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top + height <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }


    const handleApplyClick = (event, url) => {
        if(url){
            window.open(url, "_blank")
        }
        else{
            window.open(openURL, "_blank")
        }
        
    }


    const handleJobDetailsLinkClick = (event) => {
        if (!event.ctrlKey && event.which != 2 && event.which != 3) {
            handleJobDetailsClick(event)
             event.preventDefault()
        }
    }


    const handleJobDetailsClick = () => {

        if(job){
            if (page) {
                window.open(openURL, "_blank")
            }
            else {
            
            // 
                setTimeout(() => {

                    dispatch({ type: "SHOW_JOB_PAGE", job: job, searchYScroll: window.scrollY })

                    var jobID = job.slug
                    if (!jobID) {
                        jobID = job._id
                    }


                    history.push(openDetailsURL,"NORELOAD");
                
                    
                
                    
                    document.title = "Rejoco - "+job.title+" - "+job.company
                
                    window.scroll(0, 0);
                }, 10)

                //window.open(openDetailsURL, "_blank")
            
        }}


    }

    const handleSaveClick = () => {
        if(job){
            dispatch({ type: "TOOGLE_ITEM_SAVE", job: job })
        }
        
    }


    const handleItemClick = () => {
  
        if (!page) {

            var scrollIntoViewOpen = job?!job.open:false;

            var hideDetailsFast = false



            var itemHeight = null

            var collapsedItemHeight = 126

            if (job  && job.itemHeight) {
                collapsedItemHeight = job.itemHeight
            }

            if (!scrollIntoViewOpen) {
                if (itemRef.current) {
                    if (!isTopInViewport(itemRef.current, -collapsedItemHeight)) {

                        if (itemRef.current) {
                            //itemRef.current.scrollIntoView({ block: "start", behavior: "auto" });
                            var y = window.scrollY - (itemRef.current.clientHeight - collapsedItemHeight)
                            if (y >= 0) {
                                window.scroll(0, y);
                                hideDetailsFast = true
                            }
                            else {
                                hideDetailsFast = true
                                if (itemRef.current) {
                                    itemRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
                                }
                            }
                        }
                    }
                }
            }
            else {
                itemHeight = 126
                if (itemRef.current) {
                    itemHeight = itemRef.current.offsetHeight
                }
            }
            if(job){
                dispatch(({ type: "TOOGLE_ITEM_OPEN", job: job, hideDetailsFast: hideDetailsFast, itemHeight: itemHeight }))
            }
           

            if (scrollIntoViewOpen) {
                if (itemRef.current && detailsRef.current) {
                    if (!isBottomInViewport(itemRef.current, detailsRef.current.offsetHeight)) {
                        setTimeout(() => {
                            if (itemRef.current) {
                                itemRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
                            }
                        }, 10)
                    }
                }
            }
        }



    };





    var itemDetails = null
    var expandLessMore = null
    var expandLessMoreSpace = null

    var expandLessMoreBottom = null
    if(job){
        if (!job.hideDetailsFast || page) {
            if (!job.open) {
                expandLessMoreSpace = <div style={{ width: "100%", height: "10px" }}></div>
            }

            var descStyle = {}
            if (loading) {
                descStyle = { color: "#dedede" }
            }

            if (!page) {
                expandLessMoreBottom =
                    <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "41px", cursor: "pointer" }}>
                        <IconButton size="small" style={{ float: "right", "marginTop": "9px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                            <ExpandLess />
                        </IconButton>


                    </div>
            }
            else {
                expandLessMoreBottom = <div style={{ width: "100%", height: "15px" }}></div>
            }


            var applyLinkButtons =  null


            if(jobValues.applyURLs.length <= 1 ){
                if(openURL){
                    var domainPart = getDomain(openURL)
                    applyLinkButtons =  <Button style={{ float: "right",textTransform: "none",marginTop:"5px"  }} variant="contained" color="primary" onClick={(event) => { handleApplyClick(event,openURL) }} >{"Apply on "+domainPart}</Button>
                }
            }
            else{

                /*
                jobValues.applyURLs = jobValues.applyURLs.concat(jobValues.applyURLs)
                
                jobValues.applyURLs = jobValues.applyURLs.concat(jobValues.applyURLs)
                jobValues.applyURLs = jobValues.applyURLs.concat(jobValues.applyURLs)
                */
                var applyLinkButtonItems = jobValues.applyURLs.map((url,index)=>{
                    var domainPart = getDomain(url)

                    var style= { float: "right",textTransform: "none",marginTop:"5px" }
                    if(index!=jobValues.applyURLs.length-1){
                        style.marginLeft="10px"
                    }


                    return  (
                        <>
                        <Button style={style}   variant="contained" color="primary" onClick={(event) => { handleApplyClick(event,url) }} >{"Apply on "+domainPart}</Button>
                        </>
                    )
                        
                        
                    
                })
                applyLinkButtons = applyLinkButtonItems
            }

            itemDetails = <div ref={detailsRef}>

                <div >
                    <div dangerouslySetInnerHTML={{ __html: jobValues.descInnerHTML }} style={{ ...descStyle, marginTop: "1px", marginLeft: "20px", marginRight: "20px", marginBottom: "10px", whiteSpace: "pre-wrap", overflow: "auto" }} />
                </div>
                <div style={{ marginTop: "1px", marginLeft: "20px", marginRight: "20px", marginBottom: "10px" }} >

                    {applyLinkButtons}
                    {job.saved ?
                        <Button
                            style={{cursor:"pointer", float: "right", marginRight: "20px",marginTop:"5px" ,textTransform: "none" }}

                            color="primary"
                            onClick={(event) => { handleSaveClick(event) }}
                            endIcon={<BookmarkIcon />}
                        >
                            Saved
                </Button> :
                        <Button
                            style={{cursor:"pointer", float: "right", marginRight: "20px",marginTop:"5px" ,textTransform: "none" }}

                            color="primary"
                            onClick={(event) => { handleSaveClick(event) }}
                            endIcon={<BookmarkBorderIcon />}
                        >
                            Save
                </Button>

                    }



                </div>
                <div style={{ "clear": "both" }}></div>
                {expandLessMoreBottom}



            </div>



        }
    }

    if (!page) {
        if (!job || !job.open) {
            expandLessMore = (
                <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "31px", cursor: "pointer", "borderTop": "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <IconButton size="small" style={{ float: "right", "marginTop": "1px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                        <ExpandMore />
                    </IconButton>
                </div>)

        }
        else {
            expandLessMore = (
                <div onClick={() => { handleItemClick() }} style={{ width: "100%", height: "41px", cursor: "pointer", "borderTop": "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <IconButton size="small" style={{ float: "right", "marginTop": "1px", marginRight: "12px", color: "rgba(0, 0, 0, 0.54)" }} aria-label="delete">
                        <ExpandLess />
                    </IconButton>

                </div>)
        }
    }


    var jobSkills = jobValues.skills

    jobSkills = []//["React", "C++", "Javascript", "Frontend", "Backend", "Fullstack"]

    var jobSkillItems = ""
    jobSkills.forEach((item, index) => {

        var c = ""
        if (index < jobSkills.length - 1) {
            c = ","
        }

        jobSkillItems = jobSkillItems+item + c+" "

    })
    /*
    var jobSkillItems = jobSkills.map((item, index) => {

        var c = ""
        if (index < jobSkills.length - 1) {
            c = ","
        }

        return <span style={{ marginRight: "5px" }}>{item + c}</span>

    })
    */




    let precision = 1

    if (typeof jobValues.rating === 'undefined' || jobValues.rating == null) {
        jobValues.rating = 0
    }

    if (Math.abs(jobValues.rating - Math.round(jobValues.rating)) > 0.25) {
        precision = 0.5
    }

    var ratingItem = null
    
    if(jobValues.rating >= 0.5){
        ratingItem =  <div title={(Math.round(jobValues.rating*10)/10)+" ("+jobValues.ratingCount+")"} style={{ marginRight: "10px" }} ><Rating  readOnly name="size-small" defaultValue={jobValues.rating} precision={precision} size="small" /></div>
    }
   

    var handleCompanyClick = () =>{
        if(companyClick){
            companyClick()
        }
    }


    var clickStyle = {}
    if(companyClick){
        clickStyle = {cursor: "pointer"}
    }
    
    var companyPlusRating = null

    if(showCompany){
        companyPlusRating = <><span onClick={handleCompanyClick} title={job?job.company:""} style={{...clickStyle,display:"block", textOverflow: "ellipsis", whiteSpace: "normal", display: "inline-block", maxWidth: "calc(100% - 30px)", overflow: "hidden" }}>{job?job.company:""}</span>
        <span style={{display:"block", "clear": "both", height: "1px" }}></span>
        {showRating?ratingItem:null}</>
    }



    var regionText = jobValues.region //TODO
    regionText = regionText.replace("<span>","").replace("<span>","").replace("<span>","").replace("<span>","").replace("<span>","").replace("<span>","")
    regionText = regionText.replace("</span>","").replace("</span>","").replace("</span>","").replace("</span>","").replace("</span>","").replace("</span>","")
    regionText = regionText.trim()

    var listItemContent = <>
    <ListItemIcon>
                    <BlobImage width={30} height={30} src={ jobValues.logoURL}  />
                      
                    </ListItemIcon>
                    <ListItemText

                        primary={<div style={{ marginBottom: "-4px" }}> <span title={job?job.title:""} style={{ textOverflow: "ellipsis", whiteSpace: "normal", display: "inline-block", maxWidth: "calc(100% - 30px)", overflow: "hidden" }}>{job?job.title:""}</span>

                            <span onMouseDown={(event) => { event.preventDefault(); event.stopPropagation() }} onClick={(event) => { handleSaveClick(event); event.preventDefault(); event.stopPropagation() }} style={{ float: "right", height: "24px", color: "rgba(0,0,0,.54)",cursor:"pointer" }}>{(job && job.saved) ? <BookmarkIcon /> : <BookmarkBorderIcon />}</span>
                        </div>}

                        secondary={<>



                            {companyPlusRating}
                            {jobSkillItems.length > 0 ? <span style={{display:"block", "clear": "both", height: "3px" }}></span> : null}
                           
                            <span  style={{ textOverflow: "ellipsis", whiteSpace: "normal", display: "inline-block", maxWidth: "calc(100% - 30px)", overflow: "hidden",marginRight: "10px", "fontSize": "0.8rem", "color": "rgba(0, 0, 0, 0.50)" }}> {jobSkillItems}</span>
                            
                            <span style={{display:"block", "clear": "both", height: "6px" }}></span>
                           
                            {jobValues.region ? <span title={jobValues.region} style={{display:"block", marginRight: "10px", float: "left", "fontSize": "0.7rem", "color": "rgba(0, 0, 0, 0.40)", textOverflow: "ellipsis", whiteSpace: "normal", maxWidth: "calc(100% - 30px)", overflow: "hidden" }}>{"📍 " + regionText}</span> : null}
                            {/*<span style={{display:"block", marginRight: "10px", "float": "left", "fontSize": "0.7rem", "color": "rgba(0, 0, 0, 0.40)" }}>{"💶 60 000 € (est.)"}</span>*/} 
                            {/*{jobValues.easyApply?<span style={{display:"block", marginRight: "10px", "float": "left", "fontSize": "0.7rem", "color": "rgba(0, 0, 0, 0.40)" }}>{"⚡ Easy Apply"}</span>:null } */} 
                            <span style={{display:"block", marginRight: "10px", "float": "left", "fontSize": "0.7rem", "color": "rgba(0, 0, 0, 0.40)" }}>{"⏱ " + jobValues.timeago /*+ " on " + jobValues.portalText*/}</span>



                        </>}


                    />
    
    </>




    var listItem = null

    if(!page){
        listItem =  <ListItem component="div" button disabled={loading && !page} onClick={(event) => { handleJobDetailsLinkClick(event);}} component="a" href={openClickTitleURL}>
        {listItemContent}       
        </ListItem>
    }
    else{
        listItem =  <ListItem component="div" div disabled={loading && !page} >
        {listItemContent}       
        </ListItem> 
    }


    var jobItem = (
        <Paper className="jobitem" elevation={1} ref={itemRef} >
            <div className="jobitemInnerContainer" style={{}} selectedcount={jobSelectedCounter}>
                {listItem}
                {expandLessMore}

                <Collapse onExited={() => { if(job){job.hideDetailsFast = true}; dispatch(({ type: "UPDATE_ITEM_LIST" })) }} in={(job && job.open && !loading) || page} collapsedHeight={0}>
                    {expandLessMoreSpace}
                    {itemDetails}
                
                </Collapse>



            </div>
        </Paper>
    )


    return jobItem
}


export default withRouter(Company);



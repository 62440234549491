import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import ItemList from "../components/ItemList";
import ItemSearch from "../components/ItemSearchField";
import ItemFilter from "../components/ItemFilter";
import ItemSort from "../components/ItemSort";
import {fetchData} from "../data/LoadItems";



import { setDispatch } from '../data/data'


import Item from "../components/Item"; 
import { TrainRounded } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
//export default function 
import { NavLink, withRouter } from "react-router-dom";

import HomeWorkIcon from '@material-ui/icons/HomeWork';


import SimilarJobs from "../components/SimilarJobs"; 
import CompanyJobs from "../components/CompanyJobs"; 

const ItemPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    /*
    useLayoutEffect(() => {
        var pathArray = window.location.pathname.split('/');
        var jobID = ""//pathArray[1]
        if(pathArray.length>=3){
            var jobID = pathArray[2]
        }
        startResizeService(dispatch)
        dispatch(({ type: "FETCH_DATA",initialize:true,jobID:jobID}))
    }, [])
    */

    useLayoutEffect(() => {
        setDispatch(dispatch)
    })


    let filterLeftValue              = useSelector(state => state.widthValues.filterLeft?state.widthValues.filterLeft.value:0)
    let loading         =useSelector(state => state.loading);
 
    let backPageURL = useSelector(state => state.backPageURL)


    var job = useSelector(state => state.jobPageItem) 

    var  [jobTitle, setJobTitle] = React.useState( '' );

    if(job&&!jobTitle){
        var title = "Rejoco - " + job.title + " - " + job.company
        document.title = title
        setJobTitle(title)

    }


    var title = ""
    var jobItemContainer = null
    var jobItemLinks = null
    var moreJobsAtCompany = ""




    if (job) {


        var companyID = job.companySlug

        if(!companyID){
            companyID = job.companyID
        }

        var openCompanyURL = "/company/" + companyID //companySlug
        const handleCompanyDetailsClick = () => {

            setTimeout(() => {

                dispatch({ type: "SHOW_COMPANY_PAGE",companySlug:job.companySlug, companyID: job.companyID })

                history.push(openCompanyURL);
                
                document.title = "Rejoco - " + job.company

                window.scroll(0, 0);
            }, 10)

        }







        
        title = job.title
        var jobItem = <Item job={job} page={true} showRating={true} showCompany={true} companyClick={handleCompanyDetailsClick}></Item>

        if(!loading){
            jobItemContainer = (<>
                {jobItem}
                <CompanyJobs job={job}  company={job.companyInfo} moreJobs={true}/>
                <SimilarJobs job={job}/>
    
            </>)
        }
        else{
            jobItemContainer = (<>{jobItem}</>)
        }
        

        jobItemLinks = <Button  startIcon={<HomeWorkIcon fontSize="small"  />}  component="a" href={openCompanyURL} variant="outlined" size="small" onClick={(event) => {handleCompanyDetailsClick(event); event.preventDefault(); event.stopPropagation()}} style={{marginTop:"1px",marginBottom:"1px",marginRight:"20px",float:"left"}} >{job.company}</Button>

    }



    const handleCloseJobDetailsClick = () => {
        setTimeout(()=>{
            if (backPageURL=="") {
                if(job){
                    job.hideDetailsFast = false
                }
              
                window.scroll(0, 0);
                history.push("/");
                document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"

            }
            else {
                if(job){
                    job.hideDetailsFast = false
                }
                history.goBack()
                document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
            }
        },10)
       

    }


    console.log(window.location)



    var jobListingsButton =  null
    
    
    if(backPageURL==""){
        jobListingsButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<FormatListBulletedIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>All Job Listings</div></Button>
    }
    else if(backPageURL=="/"){
        jobListingsButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Job Listings</div></Button>
    }
    else {
        jobListingsButton =  <Button style={{marginTop:"1px",marginBottom:"1px",float:"left",marginRight:"20px"}} startIcon={<ArrowBackIosIcon fontSize="small"  />} variant="outlined" size="small" onClick={(event) => {handleCloseJobDetailsClick(event)}} ><div style={{marginTop:"1px"}}>Back</div></Button>
    }

    return (
        <div class="inner-container">
            {/*<ItemSearch jobPage={true}/>*/}

            <div style={{ marginTop: "15px", marginLeft: "10px" }}>
                {jobListingsButton}
                {jobItemLinks}
            </div>
            <div style={{ "clear": "both" }}></div>
            <div style={{ marginBottom: "110px", marginTop: "0px" }}>
                {jobItemContainer}

            </div>

          
        </div>
    )
}




export default withRouter(ItemPage);
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable jsx-a11y/anchor-is-valid */

import Link from '@material-ui/core/Link';
//export default function 
import { NavLink, withRouter } from "react-router-dom";

import imageAbout from '../images/about.png';

const AboutPage = (props) => {
    const { history,...other } = props;
    const dispatch = useDispatch();

    const openPage = (title,url) => (event) => {
        if (!event.ctrlKey && event.which != 2 && event.which != 3) {

            history.push(url,"NORELOAD");
              

            document.title = title
          
            window.scroll(0, 0);


            event.preventDefault()
        }
    }

    var imageAboutPath = imageAbout.replace("./", "/")

    return (
        <div>
            <div style={{ position: "absolute", top: "170px", marginLeft: "10px", marginRight: "10px" }}>
                <img src={imageAboutPath}/>
                {/*
                <span style={{ color: "#70757a" }}>{"Rejoco"}</span>  <br />
                <span style={{ color: "#70757a" }}>{"Platanenstraße 33"}</span>  <br />
                <span style={{ color: "#70757a" }}>{"82024 Taufirchen bei München"}</span>  <br />
                <span style={{ color: "#70757a" }}>{"Deutschland"}</span>  <br />
                 */
                }
                

            </div>

            <div style={{ position: "absolute", bottom: "50px", marginLeft: "10px", marginRight: "10px" }}>



                <Link href="/disclamer" onClick={openPage("Rejoco - Disclamer", "/Disclamer")}>Datenschutzerklärung</Link>
                
                {/*<br /><Link href="/terms" onClick={openPage("Rejoco - Terms of Use", "/terms")}>Terms of Use</Link>*/}
                <br />
                <br />
                <div style={{ color: "grey" }}>
                    {"© " + (new Date().getFullYear()) + " Rejoco. All Rights Reserved"}
                </div>

            </div>

            <div style={{ marginBottom: "60px", marginTop: "0px" }} />
        </div>
    )
}




export default withRouter(AboutPage);
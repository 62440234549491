/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useSelector, useDispatch } from 'react-redux'
import Chip from '@material-ui/core/Chip';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FilterAutocompleteInput(props) {
  const {showInDialog,onInputChange,onChange,arrayName,selectedArrayName,placeholderName,acceptfreeSolo, ...other } = props;
  const dispatch = useDispatch();
  var timezones = useSelector(state => state[arrayName])
  var timezonesNum = useSelector(state => state[arrayName].length)


  var selectedTimezones = useSelector(state => state[selectedArrayName])
  var selectedTimezonesNum = useSelector(state => state[selectedArrayName].length)

  var minWidth = 185
  var checkBoxStyle = { marginRight: 2,marginLeft:-15 }

  if(showInDialog){
    minWidth = "100%"
  }

  var onChangeIntern = (event, newValue) => {
    onChange(event,newValue)
  }
  var onInputChangeIntern = (event, newValue) => {
    onInputChange(event,newValue)
  }

  var freeSolo = false

  if(acceptfreeSolo){
    freeSolo = true
  }

  return (
    <Autocomplete
      freeSolo = {freeSolo}
      disableClearable
      autoHighlight
      multiple
      size="small"
      id="checkboxes-tags-demo"
      options={timezones}
      value={selectedTimezones}
      onInputChange={onInputChangeIntern}
      onChange={onChangeIntern}
      disableCloseOnSelect={false}
      getOptionLabel={(option) => option.title}
      renderOption={(option, { selected }) => {
        return(
        <React.Fragment>
          {/*
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={checkBoxStyle}
            checked={selected}
            color="primary"

          />
           */}
          {option.title}
        </React.Fragment>
        )}}
      renderTags={(tagValue, getTagProps) => {

        var chips = []

        tagValue.forEach((option, index) => {
          var props = getTagProps({ index })

          var disabled = false
          var onDelete = props.onDelete
          if(option.defaultValue){
            disabled = true
            onDelete = null
          }
  
          chips.push(<Chip size="small"
            label={option.title}
            {...props}
            disabled={disabled}
            onDelete={onDelete}
          />)

        })

        return  (chips)
      }
      }


      style={{ minWidth: minWidth}}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="" placeholder={placeholderName} 
          
        
        />
      )}
    />
  );
}
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";


import BackToTop from "react-back-to-top-button";
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';

import Fade from '@material-ui/core/Fade';

import { getTabIndex } from "../utils/Tabs";



const SettingsItem = (props) => {
    const { history,location,...other } = props;
    const dispatch = useDispatch();

    const [checked, setChecked] = React.useState(true);



    useLayoutEffect(() => {
        window.onscroll  = () =>{
            if( window.pageYOffset < 5){
                setChecked(true);
            }
            else{
                setChecked(false);
            }
        }
    
      }, [])
   
    
    
   

    return (
        <>
            <Fade in={checked}>
                <div class="Settings-Button" >
                    <Fab component="div" size="small" aria-label="settings">
                        <SettingsIcon />
                    </Fab>
                </div>
            </Fade>


        </>

    )
}




export default withRouter(SettingsItem);
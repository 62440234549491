import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//export default function 
import { NavLink, withRouter } from "react-router-dom";

import Item from "../components/Item"; 




const CompanyJobs = (props) => {
    const { company, job,history, moreJobs,location, ...other } = props;
    const dispatch = useDispatch();
    var companyJobContainer = null

    if(company){
      



        var companyJobsTitle = ""
        
        if(moreJobs){
            companyJobsTitle =  <h3>More jobs at {company.company}</h3> 
        }
        else{
            companyJobsTitle =  <h3>Jobs at {company.company}</h3> 
        }

        var companyJobItems = null
        if (company.jobList) {
            companyJobItems = company.jobList.filter(companyJob=>{
                if(moreJobs){
                    if(job){
                        if(job.slug === companyJob.slug){
                            return false
                        }
                        else{
                            return true   
                        }
                    }
                    else{
                        return true
                    }
                }
                else{
                    return true
                }

            }).map(companyJob => {
                if(companyJob){
                    return <Item job={companyJob} showRating={false} showCompany={false} />
                }
                else{
                    return null
                }
               
            })
        }
        if(companyJobItems && companyJobItems.length > 0){
            companyJobContainer = <>
            <div style={{ marginLeft: "15px", marginTop: "50px", marginBottom: "30px" }}>{companyJobsTitle}</div>
            {companyJobItems}
        </>
        }
       
    }
    return companyJobContainer
}




export default withRouter(CompanyJobs);
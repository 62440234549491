import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import ItemList from "../components/ItemList";
import ItemSearchField from "../components/ItemSearchField";
import ItemFilter from "../components/ItemFilter";
import ItemSort from "../components/ItemSort";




import {fetchData} from "../data/LoadItems";

import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';






export default function JobSearch(props) {

    const dispatch = useDispatch();


    useLayoutEffect(() => {
        document.title = "Rejoco - Remote Job Finder - Remote Jobs, Home Office Jobs, Mobile Jobs, Telework, Telecommuting"
    }, [])


    let filterLeftValue              = useSelector(state => state.widthValues.filterLeft?state.widthValues.filterLeft.value:0)
    var filterItem = <ItemFilter/>
    var filterLeftItem   = null
    if(filterLeftValue==1){
        filterLeftItem =  filterItem
    }


    return (
        <div class="inner-container">
            {filterLeftItem}

            <ItemSearchField />

            <ItemSort />


            <ItemList />
            
            <div style={{ marginBottom: "110px", marginTop: "0px" }} />


        </div>
    )
}





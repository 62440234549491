import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { createHashHistory } from 'history';

import { startResizeService } from "./components/ResizeService";
import './app.css';


//--pages--//


import CompanySearch from './pages/CompanySearch';
import ItemPage from './pages/ItemPage';
import CompanyPage from './pages/CompanyPage'

import DefaultPage from './pages/DefaultPage'
import PostJobPage from './pages/PostJobPage';
import AccountPage from './pages/AccountPage';
import WebPage from './pages/WebPage'
import NotFoundPage from './pages/NotFoundPage'
import AboutPage from './pages/AboutPage'

import MuiAlert from '@material-ui/lab/Alert';

import DisclamerPage from './pages/DisclamerPage'
import SimpleContentPage from './pages/SimpleContentPage'



import JobSearch from './pages/JobSearch'

import SavedPage from './pages/SavedPage'


import CookieConsent from './components/CookieConsent';
import { setDispatch } from './data/data'

import NavigationItem from './pages/NavigationItem'
import SettingsItem from "./pages/SettingsItem";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function App(props) {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    startResizeService(dispatch)

    

    /*
    var page = document.getElementById('body');
    if(page){
      page.style["transform-origin"] = "top"
      page.style.transform = "scale(0.5)" 
    }
    */
   



  }, [])

  useLayoutEffect(() => {
    setDispatch(dispatch)
  })



  var containerElement = <div>

    <Switch>
      <Route path="/jobs">
        <JobSearch />
      </Route>
      <Route path="/job/">
        <ItemPage />
      </Route>
      <Route path="/company/">
        <CompanyPage />
      </Route>
      <Route path="/companies/">
        <CompanySearch />
      </Route>
      <Route path="/saved/">
        <SavedPage />
      </Route>

      <Route path="/postjob/">
        <PostJobPage />
      </Route>
      <Route path="/account/">
        <AccountPage />
      </Route>

      <Route path="/about">
      
        <SimpleContentPage content={<AboutPage />} />
      </Route>

      <Route path="/disclamer/">
       <SimpleContentPage content={<DisclamerPage/>} />
      </Route>



      <Route exact path="/">
        <JobSearch />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
    <NavigationItem />
   {/*<SettingsItem/>*/}

    <CookieConsent
      cookieName={"rejocoCookieConsent"}
      componentType="Dialog" 
      message= "We and selected partners, use cookies or similar technologies as specified in the cookie policy. You can consent to the use of such technologies by closing this notice, by interacting with any link or button outside of this notice or by continuing to browse otherwise."
      
      messageDE = {"" /*Wir nutzen Cookies und vergleichbare Technologien, auch von Dritten, um unsere Dienste anzubieten, stetig zu verbessern und Inhalte sowie Werbeanzeigen personalisiert auf unserer Website, Social Media und Partnerseiten anzuzeigen (s.a. unsere Datenschutzerklärung). Standardmäßig sind nur essentielle Dienste aktiv. Erst mit Klick auf „OK“ willigst du in die übrige Datenerfassung ein. Deine Einwilligung kannst du unter „Cookie-Einstellungen“ für die Zukunft widerrufen oder ändern."*/}
      acceptButtonLabel="OK"
      title="Cookies"
    />

  </div>





  return (
    <div>
      <Router>
        <WebPage content={containerElement} />
      </Router>
    </div>

  );

}
